import { Expose, Type } from 'class-transformer'

export class CarNewInfoBase {
  @Expose()
  @Type(() => Number)
  id: number

  @Expose({ name: 'carType' })
  @Type(() => String)
  carType: string

  @Expose()
  get type() {
    return this.carType
  }

  @Expose()
  get code() {
    return this.stationCode
  }

  @Expose({ name: 'stationCode' })
  @Type(() => String)
  stationCode: string

  @Expose({ name: 'stationName' })
  @Type(() => String)
  name: string

  @Expose()
  @Type(() => String)
  remark: string

  @Expose({ name: 'licensePlateNumber' })
  @Type(() => String)
  plateNumber: string

  @Expose({ name: 'createdAt' })
  @Type(() => String)
  createdAt: string

  @Expose({ name: 'updatedAt' })
  @Type(() => String)
  updatedAt: string
}
