import { Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { GridForecastBase } from '../model'

export class GridForcastDto {
  @Expose()
  @Type(() => GridForecastBase)
  items: GridForecastBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
