// export type AppTheme = 'dark' | 'light'

import { loadStyle } from '@app/shared/utils/css-loader'
import { BehaviorSubject, Subject } from 'rxjs'

export enum AppTheme {
  dark,
  light,
}

let appTheme: AppTheme = AppTheme.light

export function getCurrentAppTheme() {
  return appTheme
}

export function getCurrentAppThemeName() {
  return AppTheme[appTheme]
}

const appThemeStyleMap: { [key in AppTheme]: string } = {
  [AppTheme.dark]: 'var-dark.css',
  [AppTheme.light]: 'var-white.css',
}

function appThemeStyleName() {
  return appThemeStyleMap[appTheme]
}

export function updateAppTheme(theme: number | string) {
  if (AppTheme[theme] == null) {
    return
  }
  if (typeof theme === 'string') {
    theme = AppTheme[theme]
  }
  appTheme = theme as number
  loadStyle(appThemeStyleName())

  localStorage.setItem(appThemeCacheKey, AppTheme[appTheme])

  themeNotifier$.next(appTheme)
}

const appThemeCacheKey = 'appThemeCacheKey'

export function loadAppTheme() {
  const cachedThemeName = localStorage.getItem(appThemeCacheKey) || AppTheme.light
  updateAppTheme(cachedThemeName)
}

const themeNotifier$ = new BehaviorSubject<AppTheme>(appTheme)
export function appThemeObservable() {
  return themeNotifier$
}
