import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'
import { format } from 'date-fns'


export class MobileStationAirQualityParams extends PageParamsPayload {
  @Expose({ name: 'publishedAtBegin' })
  @Transform(val => val instanceof Date ? format(val, 'yyyy-MM-dd HH:mm:ss') : val, { toPlainOnly: true })
  publishedAtBegin?: Date | string

  @Expose({ name: 'publishedAtEnd' })
  @Transform(val => val instanceof Date ? format(val, 'yyyy-MM-dd HH:mm:ss') : val, { toPlainOnly: true })
  publishedAtEnd?: Date | string

  @Expose({ name: 'stationId' })
  @Type(() => String)
  stationId?: string | string[]

  @Expose({ name: 'stationCode' })
  @Type(() => String)
  stationCode?: string | string[]

  constructor(params?: {
    publishedAtBegin?: Date | string,
    publishedAtEnd?: Date | string,
    stationId?: string | string[],
    stationCode?: string | string[],
    sortDesc?: string,
    sortAsc?: string,
    pageSize?: number,
    page?: number,
  }) {
    super()
    if (params) { this.initParams(params) }
  }
}
