import { Expose, Type } from 'class-transformer'

import { AreaAirQualityLqBase } from './area-air-quality.base'

export class AreaForecastDayAirQualityBaseLq {
  @Expose()
  @Type(() => Number)
  id: number

  @Expose({ name: 'area_code' })
  @Type(() => String)
  areaCode: string

  @Expose()
  @Type(() => AreaAirQualityLqBase)
  data: AreaAirQualityLqBase

  @Expose({ name: 'time_step' })
  @Type(() => String)
  intervalDay: string

  @Expose({ name: 'forecast_at' })
  @Type(() => String)
  forecastAt: string

  @Expose({ name: 'published_at' })
  @Type(() => String)
  publishedAt: string

}
