import { Expose } from 'class-transformer'

export class LoginDto {
  @Expose({ name: 'Token' })
  token: string

  @Expose()
  msg: string

  @Expose({ name: 'UserId' })
  userId: number
}
