import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ModalController, ToastController } from '@ionic/angular'
import { plainToClass } from 'class-transformer'
import { catchError, map, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
// import QueryString from 'qs'
import { ResponseType } from '../../types'
import { TokenManager } from '../token/token.manager'
import { LoginWxDto } from './dto'

// import QueryString,{stringify} from 'qs'
import { stringify } from 'qs'
import { StorageSync } from '../token/storage_sync.decorator'

// import { TokenManager } from '@app/core'



// const frontURL = 'http://192.168.2.14:8091'
const frontURL = `${window.location.protocol}//${window.location.host}/redirect`

const toast = new ToastController()

interface AuthResponse {
  expires_in: number
  refresh_token: string
  access_token: string
  code?: number
  msg?: string
}

interface ClientResponse {
  clientId: string
  clientSecretRule: string
}


interface VerificationCode {
  code: string,
  key: string
}

export interface AuthTokenBase {
  access_token: string
  refresh_token: string
  expires_in: number
}

interface ClientBase {
  clientId: string
  clientSecretRule: string
}
@Injectable({
  providedIn: 'root',
})

export class AuthService {

  constructor(
    private http: HttpClient,
    private token: TokenManager,
  ) { }

  @StorageSync('client', {
    transformOut: (str) => JSON.parse(str),
    transformIn: (obj) => JSON.stringify(obj),
  })
  private client: ClientBase

  @StorageSync('areaData', {   // 控制区域
    transformOut: (str) => JSON.parse(str),
    transformIn: (val) => JSON.stringify(val),
  })
  private areaData: any

  private _isRefreshingToken = false

  getControlArea() {
    return this.areaData;
  }

  toastMessage(message: string) {
    toast.create({
      message,
      duration: 2000,
      position: 'middle',
      cssClass: 'app-toast',
    }).then(t => t.present())
  }

  checkValid() {
    if (this.token.get() == null) {
      return false
    } else {
      // 异步刷新
      if (this.token.refreshToken && this.token.willExpire && !this._isRefreshingToken) {
        this._isRefreshingToken = true
        this.refreshToken().subscribe(res => {
          if (res) {
            this.token.set({
              token: res.access_token,
              refresh_token: res.refresh_token,
              expires_in: res.expires_in,
            })
            this._isRefreshingToken = false
          }
        }, (e) => {
        })
      }
      return true
    }
  }

  getSearchString(key, Url) {
    let str = Url;
    str = str.substring(1, str.length)
    // 以;分隔字符串，
    const arr = str.split(';')
    const obj = new Object()
    // 将每一个数组元素以=分隔并赋给obj对象
    for (let i = 0; i < arr.length; i++) {
      let tmp_arr = arr[i].split('=')
      obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1])
    }
    return obj[key]
  }


  refreshToken() {
    const params = {
      grant_type: 'refresh_token',
      refresh_token: this.token.refreshToken,
      client_id: this.client?.clientId,
      client_secret: this.client?.clientSecretRule,
    }
    return this.http.post<AuthTokenBase>(environment.baseURL + `/uaa-service/oauth/token`, stringify(params), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
  }



  loginWith(params: { username: string; password: string }) {
    return this.http.post<ResponseType>(environment.baseURL + '/uaa-service/login', stringify(params), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      withCredentials: true,
    }).toPromise()
      .then(data => {
        if (data.success) { // 成功后，缓存client信息
          return this.findClient({ username: params.username })
            .then(d => {
              if (d.success) {
                this.client = d.data
              }
              return d
            })
        }
        return data
      })
  }

  // 获取验证码
  getVerificationCode() {
    return this.http.get<ResponseType<VerificationCode>>(environment.baseURL + '/uaa-service/getVerificationCode', {
    }).toPromise()
  }

  // 登录
  async auth(params: {
    username: string;
    password: string;
    code: string;
    key: string;
    lon: number | string;
    lat: number | string;
    mark: number;
    loginAddress: any;
    mobileOpenId: any;
    openId: any
  } | { code: string }) {
    // params.client_credentials = 'wx_code'
    // const type = Reflect.has(params, 'code') ? 'authorization_code' : 'password'
    const username = Reflect.get(params, 'username')
    // const verificationCode = Reflect.get(params, 'code')
    // const verificationKey= Reflect.get(params, 'key')

    this.client = await this.findClient({ username }).then(d => d?.data)


    if (!this.client) {
      return Promise.resolve(false)
    }
    const params2 = {
      ...params,
      client_id: this.client.clientId,
      client_secret: this.client.clientSecretRule,
      grant_type: 'password',
      redirect_uri: frontURL,
      // code:verificationCode,
      // key:verificationKey,
    }

    return this.http.post<AuthResponse>(environment.baseURL + '/uaa-service/oauth/token', stringify(params2), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }).toPromise().then(d => {
      if (d.code === 50025 || d.code === 50024) {
        return d.code
      }

      if (!d.access_token) {
        return false
      }

      this.token.set({
        token: d.access_token,
        refresh_token: d.refresh_token,
        expires_in: d.expires_in,
      })
      // this.cacheToken(d.access_token)
      return true
    }).catch((err) => {

      return false
    })
  }

  markLogin(params: {
    username: string,
    password: string,
    mark: number,
    code: string,
    key: string
  }) {
    return this.http.post(environment.baseURL + `/uaa-service/login`, stringify(params), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
  }

  getAuthURL(params?: { client_id: string; redirect_uri: string }) {
    if (params == null) {
      params = { client_id: '', redirect_uri: '' }
    }
    if (!params.client_id) {
      params.client_id = this.client?.clientId ?? ''
    }
    if (!params.redirect_uri) {
      params.redirect_uri = frontURL
    }
    return `${environment.baseURL}/uaa-service/oauth/authorize?response_type=code&client_id=${params.client_id}&redirect_uri=${params.redirect_uri}`
  }

  // ws登录
  loginWx() {
    return this.http.post<LoginWxDto>(`${environment.baseURL}/wxLogin`, {})
      .pipe(
        map(response => plainToClass(LoginWxDto, response, { excludeExtraneousValues: true })),
        map((res: LoginWxDto) => res.msg),
        catchError(e => '授权失败'),
      )
  }

  // 查询client
  findClient(params: { username: string }) {
    return this.http.get<ResponseType<ClientResponse>>(environment.baseURL + '/uaa-service/findClient', {
      params,
    }).toPromise()
  }

  logout(params: { userId: string; tenantId: string; token: string }) {

    return this.http.get<ResponseType>(environment.baseURL + '/uaa-service/logout', { params }).toPromise().then(d => {
      this.token.set(null)
      return d.data
    })
  }


  // 权限
  getPermissionCode() {
    return this.http.get<ResponseType<{ code: string, name: string }[]>>(environment.baseURL + '/purview-service/userPurview/findCode?mark=5')
  }
}
