import { Expose, Type } from 'class-transformer'
import { StationWeatherData } from './station-weather-data'


export class StationWeatherBase {
  @Expose()
  @Type(() => Number)
  id: number

  @Expose({ name: 'stationCode' })
  @Type(() => String)
  stationCode: string

  @Expose({ name: 'stationName' })
  @Type(() => String)
  stationName: string

  @Expose()
  coord: { lon: number, lat: number }

  @Expose({ name: 'publishedAt' })
  @Type(() => String)
  publishedAt: string

  @Expose()
  @Type(() => StationWeatherData)
  data: StationWeatherData
}
