import { Injectable } from '@angular/core'
import { catchError, map } from 'rxjs/operators'

import { isWeixin } from '@app/shared/utils'
import { environment } from 'src/environments/environment'
import { ResponseType } from '../../types'
import { APIService } from '../api.service'

declare const wx: any

@Injectable({
  providedIn: 'root',
})
export class WxService extends APIService {
  wxEntrance: string

  wxjsCondig(params: { debug: boolean; jsApiList: string[] }) {
    const url =
      navigator.userAgent.indexOf('iPhone') !== -1
        ? this.wxEntrance
        : encodeURIComponent(location.href)
        console.log('wxEntrance_Url',url)

    return this.http
      .get<
        ResponseType<{
          appId: string;
          noncestr: string;
          signature: string;
          timestamp: string;
        }>
      >(`${environment.baseURL}/event-push/weChat/getSha`, {
        params: {
          // debug: String(params.debug),
          jsApiList: params.jsApiList,
          url,
        },
      })
      .pipe(
        map((res) => {
            return res.data
        }),
        catchError(this.handleError),
      )
  }

  configApi(apis: string[]) {

    return new Promise<void>((resolve, reject) => {
      if (!isWeixin()) {
        reject()
        return
      }
      this.wxjsCondig({
        debug: false,
        jsApiList: apis,
      }).subscribe((x) => {

        console.log('---- ', x, apis)
        debugger
        // const cacheConfig = localStorage.getItem('cacheConfig')
        // if (!cacheConfig) {
        //   localStorage.setItem('cacheConfig', JSON.stringify(x))
        // }
        // if (cacheConfig) {
        //   x = JSON.parse(cacheConfig)
        // }
        console.log('后端返回API',apis)
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: x.appId, // 必填，公众号的唯一标识
          timestamp: Number(x.timestamp), // 必填，生成签名的时间戳
          nonceStr: x.noncestr, // 必填，生成签名的随机串
          signature: x.signature, // 必填，签名
          jsApiList: apis, // 必填，需要使用的JS接口列表
        })
        wx.ready((params) => {
          wx.checkJsApi({
            jsApiList: ['chooseImage'],
            success(res) {
              console.log('+++++++++',res)
            },
            fail(err) {
             console.log('---------',err)
            },
          })
          resolve()
        })
        wx.error((err) => {
          console.error('*******',err)
          resolve()
        })
      }, err => {
        reject(err)
      })
    })
  }

  configTotalAPI() {
    return this.configApi(['getLocation','openLocation','chooseMedia','chooseImage','getLocalImgData','updateAppMessageShareData'])
  }

  getLocation(): Promise<{ lat: number; lng: number }> {
    return this.configTotalAPI().then(() => {
      return new Promise((r, j) => {
        wx.getLocation({
          type: 'gcj02',
          isHighAccuracy: true,
          success(res) {
            r({
              lat: res.latitude,
              lng: res.longitude,
            })
          },
          fail(err) {

            j(err)
          },
        })
      })
    })
  }

  openLocation(locationInfo: {
    lat: number;
    lon: number;
    name: string;
    address?: string;
    scale?: number;
  }) {
    return this.configTotalAPI().then(() => {
      return new Promise<void>((resolve, reject) => {
        wx.openLocation({
          latitude: Number(locationInfo.lat),
          longitude: Number(locationInfo.lon),
          name: locationInfo.name,
          address: locationInfo.address,
          scale: locationInfo.scale,
          success: () => resolve(),
          fail: () => reject(),
        })
      })


    })
  }

  // chooseaVideo(opt: {
  //   count: number;
  //   sizeType: ('original' | 'compressed')[];
  //   sourceType: ('album' | 'camera')[];
  // }): Promise<string[]> {
  //   return this.configTotalAPI().then(() => {
  //     return new Promise((resolve, reject) => {
  //       wx.chooseMedia({
  //         ...opt,
  //         success(res) {
  //            console.log('选择视频的返回',res)
  //           if (res.errMsg === 'chooseMedia:ok') {
  //             const localIds = res.localIds
  //             resolve(localIds)
  //           } else {
  //             reject(new Error(res.errMsg))
  //           }
  //         },
  //         fail(e) {
  //           reject(new Error(e.errMsg))
  //         },
  //       })
  //     })
  //   })
  // }

  chooseImage(opt: {
    count: number;
    sizeType: ('original' | 'compressed')[];
    sourceType: ('album' | 'camera')[];
  }): Promise<string[]> {
    return this.configTotalAPI().then(() => {
      return new Promise((resolve, reject) => {
        // wx.chooseMedia({
        //   ...opt,
        //   success(res) {
        //     console.log('选择视频的返回',res)
        //     if (res.errMsg === 'chooseImage:ok') {
        //       const localIds = res.localIds
        //       resolve(localIds)
        //     } else {
        //       reject(new Error(res.errMsg))
        //     }
        //   },
        //   fail(e) {
        //     reject(new Error(e.errMsg))
        //   },
        // })
        wx.chooseImage({
          ...opt,
          success(res) {
            console.log('选择视频的返回',res)
            if (res.errMsg === 'chooseImage:ok') {
              const localIds = res.localIds
              resolve(localIds)
            } else {
              reject(new Error(res.errMsg))
            }
          },
          fail(e) {
            reject(new Error(e.errMsg))
          },
        })
      })
    })
  }

  getImageData(image: { localId: string }): Promise<string> {
    return this.configTotalAPI().then(() => {
      return new Promise((resolve, reject) => {
        wx.getLocalImgData({
          localId: image.localId,
          success: (res) => {
            if (res.errMsg === 'getLocalImgData:ok') {
              resolve(res.localData)
            } else {
              reject(res.errMsg)
            }
          },
        })
      })
    })
  }

  // 更改分享的内容
  updateAppMessageShareData(data: {
    title?: string;
    desc?: string;
    link?: string;
    imgUrl?: string;
  }) {
    return this.configTotalAPI().then(() => {
      wx.updateAppMessageShareData({
        ...data,
        success: () => {
          // 设置成功
          console.log('...设置成功')
        },
        error: () => {
          console.log('... 设置失败')
        },
      })
    })
  }

  bindWxCode(code: string) {
    const existcode = this.storage.get('wxcode')
    if (!existcode || existcode !== code || true) {
      this.http
        .get<ResponseType>(
          `${environment.baseURL}/purview-service/callBack?code=${code}`,
        )
        .subscribe((data) => {
          if (data.success) {

            this.storage.set('wxcode', code)
          } else {
          }
        })
    }
  }

  getWxAuthURL(url?: string) {
    return this.http.get<ResponseType>(
      `${environment.baseURL}/purview-service/wxLogin`,
      {
        params: {
          redirect_uri: url,
        },
      },
    )
  }
}
