import { Expose, Type } from 'class-transformer'

export class Coord {
  @Expose()
  @Type(() => Number)
  lat: number

  @Expose({ name: 'lng' })
  @Type(() => Number)
  lng1: number

  @Expose({ name: 'lon' })
  @Type(() => Number)
  lon1: number

  @Expose()
  get lng() {
    return this.lon1 || this.lng1
  }

  @Expose()
  get lon() {
    return this.lon1 || this.lng1
  }

  constructor(d?: {
    lat?: number,
    lon?: number,
    lng?: number,
  }) {
    if (d) {
      this.lat = d.lat
      this.lon1 = d.lon
      this.lng1 = d.lng
    }
  }

  get lnglat(): [number, number] {
    return [this.lng, this.lat]
  }
}
