import { Expose, Type } from 'class-transformer'

import { ItemList, Meta } from '@app/core/services/types'
import { AreaForecastDayAirQualityBaseLq } from '../model'

export class AreaForecastDayAirQualityDtoLq implements ItemList<AreaForecastDayAirQualityBaseLq> {
  @Expose()
  @Type(() => AreaForecastDayAirQualityBaseLq)
  items: AreaForecastDayAirQualityBaseLq[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
