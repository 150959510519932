import { AirStationType, Coord } from '@app/core/services/types'

import { Expose, Type } from 'class-transformer'


export class FixedStationBase {

  @Expose()
  @Type(() => Coord)
  coord: Coord

  @Expose()
  cityCode: string

  @Expose()
  districtCode: string

  @Expose()
  location: string

  @Expose()
  stationCode: string

  @Expose()
  stationLevel: string

  @Expose()
  stationName: string

  @Expose()
  stationType: AirStationType

  @Expose()
  status: number

  @Expose()
  id: number

  @Expose()
  district: string

  @Expose()
  gridCode: string

  @Expose()
  gridId: number

  @Expose()
  gridLocation: string
  deviceId: string
}
