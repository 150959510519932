
import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'

export class AreaHourTrafficParams extends PageParamsPayload {
  @Expose({ name: 'area_code' })
  @Type(() => String)
  areaCode?: string[] | string

  @Expose()
  @Type(() => String)
  publishedAtBegin?: string

  @Expose()
  @Type(() => String)
  publishedAtEnd?: string

  constructor(params: {
    areaCode?: string[] | string,
    publishedAtBegin?: string,
    publishedAtEnd?: string,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
