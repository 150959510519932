import { ItemList, Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { CarNewInfoBase } from '../model/car-new-info-base'


export class GetNewCarInfoDto implements ItemList<CarNewInfoBase> {

  @Expose()
  @Type(() => CarNewInfoBase)
  items: CarNewInfoBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
