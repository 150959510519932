

export function isWeixin() {
  const ua = navigator.userAgent.toLowerCase()
  const result = ua.indexOf('micromessenger') !== -1
  return result
}


export function isIphone() {
  return navigator.userAgent.indexOf('iPhone') !== -1
}
