import { PageParamsPayload } from '@app/core/services/types'
import { arrayString } from '@app/shared/utils'
import { Expose, Transform, Type } from 'class-transformer'


export class GetNewCarInfoParams extends PageParamsPayload {

  /** 单个查询 */
  @Expose({ name: 'station_code' })
  @Type(() => String)
  stationCode: string

  @Expose({ name: 'station_code:in' })
  @Transform(value => value && arrayString(value))
  stationCodeIn: string[]

  @Expose()
  @Type(() => Number)
  id: number

  @Expose({ name: 'id:in' })
  @Transform(value => value && arrayString(value))
  idIn: number[]

  @Expose({ name: 'page_size' })
  @Type(() => Number)
  perPage?: number

  @Expose()
  isEnable?: number


  constructor(params?: {
    stationCode?: string,
    stationCodeIn?: string[],
    id?: number,
    idIn?: number[],
    perPage?: number,
    page?: number,
    order?: { [key: string]: string },
    isEnable?: number,
  }) {
    super()
    if (params) { this.initParams(params) }
  }
}
