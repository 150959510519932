import { Injectable, Optional } from '@angular/core'
import { WxService } from '@app/core/services/api'
import { isWeixin } from '@app/shared/utils'
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx'
import { Geolocation } from '@ionic-native/geolocation/ngx'
import { Platform } from '@ionic/angular'

import { MapLoaderService } from '../services/map-loader/map-loader.service'

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  wPId: number

  constructor(
    private plugin: MapLoaderService,
    private wx: WxService,
    private platform: Platform,
    @Optional()
    private androidPermission: AndroidPermissions,
    @Optional()
    private geoLocation: Geolocation,
  ) { }

  getAddressWithLngLat(lnglat: [number, number]): Promise<string> {
    return new Promise((resolve, reject) => {
      this.plugin.load().then(Amap => {

        const geocoder = new (AMap as any).Geocoder({ city: '成都', extensions: 'all' })
        geocoder.getAddress(lnglat, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            resolve(result.regeocode.formattedAddress as string)
          } else {
            reject()
          }
        })
      })
    })
  }

  getLocation(): Promise<{ lat: number, lng: number }> {
    // if (this.platform.is('hybrid') && this.platform.is('android')) {
    if (this.platform.is('hybrid') && this.platform.is('android')) {

      return this.androidPermission.requestPermissions([
        this.androidPermission.PERMISSION.ACCESS_COARSE_LOCATION,
        this.androidPermission.PERMISSION.ACCESS_FINE_LOCATION,
        this.androidPermission.PERMISSION.ACCESS_LOCATION_EXTRA_COMMANDS,
      ]).then(x => {
        console.log('get permission', x)
        if (x.hasPermission) {
          return this.geoLocation.getCurrentPosition({ enableHighAccuracy: true }).then(async geo => {
            const map = await this.plugin.load()
            return new Promise((resolve, reject) => {
              map.convertFrom([geo.coords.longitude, geo.coords.latitude], 'gps', (status, result) => {
                if (result.info === 'ok') {
                  console.log('高德地图app端', result.locations[0])
                  // return {
                  //   lng: result.locations[0].lng,
                  //   lat: result.locations[0].lat,
                  // }
                  resolve({
                    lng: result.locations[0].lng,
                    lat: result.locations[0].lat,
                  })
                }
              })
            })

            // return {
            //   lng: geo.coords.longitude,
            //   lat: geo.coords.latitude,
            // }
          })
        } else {
          throw new Error('no permission')
        }
      })
    } else if (location.protocol === 'https:') {
      return new Promise((resolve, reject) => {
        window.navigator.geolocation.getCurrentPosition(async (curLoc) => {
          const map = await this.plugin.load()

          map.convertFrom([curLoc.coords.longitude, curLoc.coords.latitude], 'gps', (status, result) => {
            if (result.info === 'ok') {
              console.log(result.locations[0])
              resolve({
                lng: result.locations[0].lng,
                lat: result.locations[0].lat,
              })
            }
          })

        }, (err) => {
          reject(err)
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
        })
      })
    }
    if (isWeixin()) {
      return this.wx.getLocation()
    } else {
      return Promise.reject('cannot locate')
    }
  }

  watchPosition(): Promise<{ lat: number, lng: number }> {
    if (this.platform.is('hybrid') && this.platform.is('android')) {


      return this.androidPermission.requestPermissions([
        this.androidPermission.PERMISSION.ACCESS_COARSE_LOCATION,
        this.androidPermission.PERMISSION.ACCESS_FINE_LOCATION,
        this.androidPermission.PERMISSION.ACCESS_LOCATION_EXTRA_COMMANDS,
      ]).then(x => {
        console.log('get permission', x)
        if (x.hasPermission) {
          return this.geoLocation.watchPosition().toPromise().then(async (geo: any) => {
            const map = await this.plugin.load()
            return new Promise((resolve, reject) => {
              map.convertFrom([geo.coords.longitude, geo.coords.latitude], 'gps', (status, result) => {
                if (result.info === 'ok') {
                  console.log('高德地图app端', result.locations[0])
                  // return {
                  //   lng: result.locations[0].lng,
                  //   lat: result.locations[0].lat,
                  // }
                  resolve({
                    lng: result.locations[0].lng,
                    lat: result.locations[0].lat,
                  })
                }
              })
            })
          })
        } else {
          throw new Error('no permission')
        }
      })
    } else if (location.protocol === 'https:' || isWeixin()) {
      return new Promise((resolve, reject) => {
        this.wPId = window.navigator.geolocation.watchPosition(async (curLoc) => {

          const map = await this.plugin.load()

          map.convertFrom([curLoc.coords.longitude, curLoc.coords.latitude], 'gps', (status, result) => {
            if (result.info === 'ok') {
              console.log(result.locations[0])
              resolve({
                lng: result.locations[0].lng,
                lat: result.locations[0].lat,
              })
            }
          })
        }, (err) => {
          reject(err)
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
        })
      })
    }
    else {
      return Promise.reject('cannot locate')
    }
  }

  closeWatchPosition() {
    console.log('wpID', this.wPId)
    window.navigator.geolocation.clearWatch(this.wPId)
  }

}
