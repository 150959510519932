import { Expose, Type } from 'class-transformer'
import { AreaAirQualityData } from './area-air-quality-data'


export class AreaAirQualityBase {
  @Expose()
  @Type(() => Number)
  id: number

  @Expose()
  @Type(() => String)
  source: string

  @Expose()
  @Type(() => AreaAirQualityData)
  data: AreaAirQualityData

  @Expose()
  @Type(() => String)
  publishedAt: string

  @Expose()
  @Type(() => Number)
  areaId: number

  @Expose()
  @Type(() => String)
  areaCode: string

  @Expose()
  @Type(() => String)
  areaName: string

  @Expose()
  @Type(() => String)
  areaType: string
}
