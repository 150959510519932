import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment'
import { ResponseType } from '../types'
import { KeyAreaBase, PollutionListBase } from "./base";
import { PollutionListParams } from "./params/distribution-pollution-list.params";
@Injectable({
	providedIn: 'root',
})
export class DistributionService {
    constructor(
		private http: HttpClient,
	) {
	}

  //获取待派发列表
    findPollutionList(params:PollutionListParams) {
        return this.http.post<ResponseType<PollutionListBase>>(environment.baseURL + '/event-push/event-distribution/findPollutionList', params)
    }
    getDistributionInfo(id) {
      return this.http.get<ResponseType<any>>(environment.baseURL + `/event-push/pushTargetAndPollutionSource/select/${id}`)
    }

    findReportOne(id) {
      return this.http.get<ResponseType<any>>(environment.baseURL + `/event-push/report/findReportOne/${id}`)
    }

    waitDispatchWithdraw(id: number) {
      return this.http.post<ResponseType<any>>(environment.baseURL +'/event-push/task/log/trace/updatePushWithdraw', {
        pushTargetId: id,
      })
    }
    // 复核无误
  affirmEvent(id: number, pushTargetId: number) {
    return this.http.post<ResponseType<any>>(environment.baseURL+'/event-push/task/log/trace/no/error/in/review', {
      id,
      pushTargetId,
    })
  }

  getKeyArea() {
    return this.http.get<ResponseType<any>>(environment.baseURL+`/platform-alpha-statistical/keyArea/getKeyAreaList`)
  }
}