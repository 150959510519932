import {
  HttpErrorResponse,
  HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TokenManager } from './token'
import * as Sentry from "@sentry/angular"
import { tap } from 'rxjs/operators'
import { Severity } from '@sentry/angular'
import { environment } from 'src/environments/environment'
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenManager,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // const token = this.state.getToken()
    const token = this.token.get()
    let nextReq: HttpRequest<any> = req

    // 添加token头信息
    if (token) {
      nextReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          // 'Referer':'https://am.glzt.com.cn/'
        },
      })
    } else {
      // 设置接收的内容类型

      nextReq = req.clone({
        setHeaders: { Accept: 'application/json' },
      })
    }

    /** add logging */


    return next.handle(nextReq).pipe(
      tap(event => {
        if (event instanceof HttpErrorResponse && environment.production) {
          Sentry.captureMessage(JSON.stringify(req.body), {
            level: Severity.Log,
            user: {
              id: this.token.get(),
            },
          })
          Sentry.captureMessage(JSON.stringify(event.error), {
            level: Severity.Log,
            user: {
              id: this.token.get(),
            },
          })
        }
        if (event instanceof HttpResponse && environment.production) {
          if (!event.body?.success) {
            Sentry.captureMessage(JSON.stringify(req.body), {
              level: Severity.Log,
              user: {
                id: this.token.get(),
              },
            })
            Sentry.captureMessage(JSON.stringify(event.body), {
              level: Severity.Log,
              user: {
                id: this.token.get(),
              },
            })
          }
        }
      }),
    )
  }
}
