export {
  arrayString,
  windLevelWithSpeed,
}

function arrayString(arr: (string | number)[]) {
  if (!arr || !Array.isArray(arr)) { return '[]' }
  if (arr && arr.length > 0) {
    if (typeof arr[0] === 'number') {
      return `[${arr}]`
    } else {
      return `[${arr.map(item => (item as any instanceof Array) ? arrayString(item as any) : (`"${item}"`)).join(',')}]`
    }
  }
}

function windDirectionName(degrees: number | string) {
  if ((!degrees && degrees !== 0) || isNaN(Number(degrees))) {
    // throw new Error('degrees参数不存在')
    return degrees
  }
  if (degrees < 0) { throw new Error('degrees参数小于0') }
  degrees = Number(degrees) % 360

  if (degrees > 337.5 || degrees <= 22.5) {
    return '北风'
  } else if (degrees <= 67.5) {
    return '东北风'
  } else if (degrees <= 112.5) {
    return '东风'
  } else if (degrees <= 157.5) {
    return '东南风'
  } else if (degrees <= 202.5) {
    return '南风'
  } else if (degrees <= 247.5) {
    return '西南风'
  } else if (degrees <= 292.5) {
    return '西风'
  } else if (degrees <= 337.5) {
    return '西北风'
  }
  return '未知'
}

function windLevelWithSpeed(speed: number) {
  const levelPoints = [
    0, 0.2, 1.5, 3.3, 5.4, 7.9, 10.7, 13.8, 17.1, 20.7, 24.4, 28.4, 32.6, 41.4, 46.1, 50.9, 56.0, 61.2, 1000,
  ]
  const level = levelPoints.findIndex(point => speed < point)
  return level - 1
}


