import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Transform } from 'class-transformer'
import { format } from 'date-fns'

export class GridForecastParams extends PageParamsPayload {

  @Expose({ name: 'gridId' })
  gridId: number

  @Expose({ name: 'publishedAtBegin' })
  @Transform((date) => format(date, 'yyyy-MM-dd HH:mm:ss'), { toPlainOnly: true })
  publishBegin: Date

  @Expose({ name: 'publishedAtEnd' })
  @Transform(date => format(date, 'yyyy-MM-dd HH:mm:ss'), { toPlainOnly: true })
  publishedEnd: Date

  @Expose({ name: 'forecastInterval' })
  interval: number

  constructor(params?: {
    gridId?: number,
    publishBegin?: Date,
    publishedEnd?: Date,
    interval?: number,
    sortAsc?: 'forecast_at',
    page: number,
    pageSize: number,
  }) {
    super()
    this.initParams(params)
  }
}
