import { PageParamsPayload, ParamsPayload } from '@app/core/services/types'
import { Expose, Transform } from 'class-transformer'


export class GetGridFeaturesParams extends PageParamsPayload {
  @Expose()
  gridId: number | number[]

  @Expose()
  // @Transform((val: Date) => val && val.toISOString(), { toPlainOnly: true })
  publishedAtBegin: string

  @Expose()
  // @Transform((val: Date) => val && val.toISOString(), { toPlainOnly: true })
  publishedAtEnd: string

  @Expose()
  isOwned?: boolean
}
