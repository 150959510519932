import { Expose, Transform } from 'class-transformer'
import { GridDataBase } from './grid-data-base'

export class GridDustBase {

  @Expose({ name: 'grid_id' })
  gridId: number

  @Expose({ name: 'published_at' })
  @Transform((val: string) => val && new Date(val), { toClassOnly: true })
  publishedAt: Date

  @Expose()
  dust: number


  @Expose()
  grid: GridDataBase
}
