import { Injectable } from '@angular/core'
import { APIService } from '@app/core/services/api'
import { plainToClass } from 'class-transformer'
import { catchError, map, tap } from 'rxjs/operators'
import { GridDustDto } from './dto/grid-dust.dto'
import { LQGridListBaseDto } from './dto/lq-grid-list-base.dto'
import { GridQueryParams } from './interface/params/grid-query.params'

import { headertoJson } from '@app/shared/utils'
import { endOfHour, format, startOfHour } from 'date-fns'
import { from } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ResponseType, StaticFeatureBase, TrafficsBase, WeatherBase } from '../types'
import { AreaGridDto, GridDayAirQualitiesDto, GridForcastDto, GridListBaseDto } from './dto'
import { GridFeaturesDto } from './dto/grid-static-features.dto'
import {
  AreaGridParams,
  CreateAreasParams,
  DeleteAreasParams,
  DeleteGridParams,
  ModifyAreasParams,
  ModifyGridParams,
} from './interface'
import {  FindGridParams, GridBigTruckBase, GridDustBase, GridListTypePara } from './model'
import { GetGridFeaturesParams } from './model/params/get-grid-features.params'
import { GridForecastParams } from './model/params/grid-forecast.params'
import { GridDustLevelBase } from './model/base/grid-dust-level.base'

@Injectable({
  providedIn: 'root',
})
export class GridService extends APIService {


  findLQGrid(params: FindGridParams, cache = true) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grids/with-area`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(LQGridListBaseDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  // 获取网格标签列表
  findAreaGrid(params: AreaGridParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/areaGrid`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(LQGridListBaseDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  //获取网格污染物特征
  findPolluteData(gridId) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/${gridId}`
  )
  }

  //保存网格
  saveGird(gridId) {
    return this.http.get(`${environment.baseURL}/platform-alpha-statistical/gridCollect/collect/${gridId}`)
  }
  //取消保存网格
  cancelSaveGird(gridId) {
    return this.http.get(`${environment.baseURL}/platform-alpha-statistical/gridCollect/cancel/${gridId}`)
  }

  //收藏网格列表
  saveGridList() {
    return this.http.get(`${environment.baseURL}/platform-alpha-statistical/gridCollect/list`)
  }



  // 网格静态特征列表
  getGridStaticFeatures(params: GetGridFeaturesParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/static-feature`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridFeaturesDto, res.data, { excludeExtraneousValues: true })),
      tap(res => {
        if (res?.items) {
          res.items.forEach(item => item.data = plainToClass(StaticFeatureBase, item.data, { excludeExtraneousValues: true }))
        }
      }),
    )
  }

  // 网格小时气象列表
  getGridWeatherFeatures(params: GetGridFeaturesParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/hour-weather`, {
      params: params.bracketParams(),
    }).pipe(
      map(res => plainToClass(GridFeaturesDto, res.data, { excludeExtraneousValues: true })),
      tap(res => {
        if (res?.items) {
          res.items.forEach(item => item.data = plainToClass(WeatherBase, item.data, { excludeExtraneousValues: true }))
        }
      }),
    )
  }

  // 网格最新小时天气列表
  getGridLastHourWeather(params: GetGridFeaturesParams) {
    return this.http.get<GridFeaturesDto>(`${this.coreCofnig.baseURL}/grid/latest-hour-weather`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridFeaturesDto, res, { excludeExtraneousValues: true })),
    )
  }

  // 网格小时交通列表
  getGridTrafficFeatures(params: GetGridFeaturesParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/hour-traffic`, {
      params: params.bracketParams(),
    }).pipe(
      map(res => plainToClass(GridFeaturesDto, res.data, { excludeExtraneousValues: true })),
      tap(res => res.items.forEach(item => item.data = plainToClass(TrafficsBase, item.data, { excludeExtraneousValues: true }))),
    )
  }

  // 网格最新小时交通列表
  getGridLastHourTrafficFeatures(params: GetGridFeaturesParams) {
    return this.http.get<GridFeaturesDto>(`${this.coreCofnig.baseURL}/platform-alpha-statistical/grid/latest-hour-traffic`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridFeaturesDto, res, { excludeExtraneousValues: true })),
    )
  }

  // 网格最新小时空气质量列表
  getGridLastHourAirQualities(params: GridQueryParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/latest-hour-air-quality`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridDayAirQualitiesDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  // 获取最新有站点数据的时间
  getLatestGridDataTime() {
    const params = new GridQueryParams()
    params.pageSize = 1
    params.page = 1
    // params.publishedAtBegin = format(startOfHour(new Date()), 'yyyy-MM-dd HH:mm:ss')
    // params.publishedAtEnd = format(endOfHour(new Date()), 'yyyy-MM-dd HH:mm:ss')
    return this.getGridLastHourAirQualities(params).pipe(
      map(res => res.items[0].publishedAt),
    )
  }

  // 网格小时空气质量列表
  getHourAirQualities(params: GridQueryParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/hour-air-quality`, {
      params: params.bracketParams(),
    }).pipe(
      map(res => plainToClass(GridDayAirQualitiesDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  // 网格日空气质量列表
  getGridDayAirQualities(params: GridQueryParams) {
    return this.http.get<GridDayAirQualitiesDto>(`${this.coreCofnig.baseURL}/grid/day-air-quality`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridDayAirQualitiesDto, res, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getGridDust(params: GridQueryParams) {
    return this.http.get<GridDustDto>(`http://192.168.2.10:8080/api/v1/dust`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridDustDto, res, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getForecastLatest(params: { forecast_interval: number }) {
    return this.http.get<GridForcastDto>(`${this.coreCofnig.baseURL}/grid/latest-forecast-hour-aq`, {
      params: {
        forecast_interval: params.forecast_interval.toFixed(1),
      },
    }).pipe(
      map(res => plainToClass(GridForcastDto, res, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getHourForecast(params: GridForecastParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-forecast/grid/forecast-hour-air-quality`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GridForcastDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  // 网格获取重型车和扬尘
  // 扬尘
  getGridDustLevel(params: {
    gridId?: number,
    publishedAt: string,
  }) {
    return this.http.get<ResponseType<GridDustLevelBase[]>>(`${environment.baseURL}/platform-decision-making/pollution/getTruckDustTrafficFlowGrid`, {
      params: params as any,
    })
  }

  // 重型车
  getGridBigTruck(params: {
    gridId?: number,
    publishedAt: string,
  }) {
    return this.http.get<ResponseType<GridBigTruckBase[]>>(`${environment.baseURL}/platform-decision-making/pollution/getSlagTruckGridHourCount`, {
      params: params as any,
    })
  }
}
