import { Injectable } from '@angular/core'
import { plainToClass } from 'class-transformer'
import { catchError, map } from 'rxjs/operators'

import { APIService } from '@app/core/services/api'


import { environment } from 'src/environments/environment'
import { GetNewCarInfoDto } from './dto/get-new-car-info.dto'
import { GetNewCarInfoParams } from './params/get-new-car-info.params'

@Injectable({
  providedIn: 'root',
})
export class AirQualityStationService extends APIService {


  /** 获取车辆信息, 移动站数据 (lq) */
  getNewCarInfos(param: GetNewCarInfoParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/mobile-station/air-quality`, {
      params: param.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GetNewCarInfoDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

}
