

export const REGIONS = [
  { key: 1, title: '锦江区' },
  { key: 2, title: '青羊区' },
  { key: 3, title: '金牛区' },
  { key: 4, title: '武侯区' },
  { key: 5, title: '成华区' },
  { key: 6, title: '龙泉驿区' },
  { key: 7, title: '新都区' },
  { key: 8, title: '温江区' },
  { key: 9, title: '青白江区' },
  { key: 10, title: '双流区' },
  { key: 11, title: '郫都区' },
]
