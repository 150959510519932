import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'
import { format } from 'date-fns'


export class StationFeatureParams extends PageParamsPayload {

  @Expose({ name: 'stationCode' })
  @Type(() => String)
  stationCode?: string[]

  @Expose({ name: 'publishedAtBegin' })
  @Transform(val => (val instanceof Date) ? format(val, 'yyyy-MM-dd HH:mm:ss') : val, { toPlainOnly: true })
  publishedAtBegin?: Date | string

  @Expose({ name: 'publishedAtEnd' })
  @Transform(val => (val instanceof Date) ? format(val, 'yyyy-MM-dd HH:mm:ss') : val, { toPlainOnly: true })
  publishedAtEnd?: Date | string

  constructor(params?: {
    stationCode?: string[],
    publishedAtBegin?: Date,
    publishedAtEnd?: Date,
    page: number,
    pageSize: number,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
