import { Expose, Type } from 'class-transformer'

export class StaticFeatureBase {
  @Expose()
  @Type(() => Number)
  'dem'?: number

  @Expose({ name: 'gov_num' })
  @Type(() => Number)
  'govNum'?: number

  @Expose({ name: 'gov_area' })
  @Type(() => Number)
  'govArea'?: number

  @Expose({ name: 'grid_row' })
  @Type(() => Number)
  'gridRow'?: number

  @Expose({ name: 'food_area' })
  @Type(() => Number)
  'foodArea'?: number

  @Expose({ name: 'shop_area' })
  @Type(() => Number)
  'shopArea'?: number

  @Expose({ name: 'green_area' })
  @Type(() => Number)
  'greenArea'?: number

  @Expose({ name: 'hotel_area' })
  @Type(() => Number)
  'hotelArea'?: number

  @Expose({ name: 'sport_area' })
  @Type(() => Number)
  'sportArea'?: number

  @Expose({ name: 'water_area' })
  @Type(() => Number)
  'waterArea'?: number

  @Expose({ name: 'company_num' })
  @Type(() => Number)
  'companyNum'?: number

  @Expose({ name: 'factory_num' })
  @Type(() => Number)
  'factoryNum'?: number

  @Expose({ name: 'grid_column' })
  @Type(() => Number)
  'gridColumn'?: number

  /**
   * 餐饮数量
   */
  @Expose({ name: 'catering_num' })
  @Type(() => Number)
  'cateringNum'?: number

  @Expose({ name: 'company_area' })
  @Type(() => Number)
  'companyArea'?: number

  @Expose({ name: 'culture_area' })
  @Type(() => Number)
  'cultureArea'?: number

  @Expose({ name: 'factory_area' })
  @Type(() => Number)
  'factoryArea'?: number

  @Expose({ name: 'leisure_area' })
  @Type(() => Number)
  'leisureArea'?: number

  @Expose({ name: 'medical_area' })
  @Type(() => Number)
  'medicalArea'?: number

  @Expose({ name: 'traffic_area' })
  @Type(() => Number)
  'trafficArea'?: number

  @Expose({ name: 'apartment_num' })
  @Type(() => Number)
  'apartmentNum'?: number

  @Expose({ name: 'education_area' })
  @Type(() => Number)
  'educationArea'?: number

  @Expose({ name: 'financial_area' })
  @Type(() => Number)
  'financialArea'?: number

  @Expose({ name: 'scenic_spot_num' })
  @Type(() => Number)
  'scenicSpotNum'?: number

  @Expose({ name: 'auto_service_num' })
  @Type(() => Number)
  'autoServiceNum'?: number

  @Expose({ name: 'life_service_num' })
  @Type(() => Number)
  'lifeServiceNum'?: number

  /**
   * 住宅面积
   */
  @Expose({ name: 'real_estate_area' })
  @Type(() => Number)
  'realEstateArea'?: number

  @Expose({ name: 'scenic_spot_area' })
  @Type(() => Number)
  'scenicSpotArea'?: number

  @Expose({ name: 'shop_service_num' })
  @Type(() => Number)
  'shopServiceNum'?: number

  @Expose({ name: 'auto_service_area' })
  @Type(() => Number)
  'autoServiceArea'?: number

  @Expose({ name: 'life_service_area' })
  @Type(() => Number)
  'lifeServiceArea'?: number

  @Expose({ name: 'sport_service_num' })
  @Type(() => Number)
  'sportServiceNum'?: number

  @Expose({ name: 'traffic_light_num' })
  @Type(() => Number)
  'trafficLightNum'?: number

  @Expose({ name: 'weibo_check_in_num' })
  @Type(() => Number)
  'weiboCheckInNum'?: number

  /**
   * 医疗服务数量
   */
  @Expose({ name: 'medical_service_num' })
  @Type(() => Number)
  'medicalServiceNum'?: number

  @Expose({ name: 'poi_num' })
  @Type(() => Number)
  'poiNum'?: number

  @Expose({ name: 'public_facility_num' })
  @Type(() => Number)
  'publicFacilityNum'?: number

  @Expose({ name: 'road_network_length' })
  @Type(() => Number)
  'roadNetworkLength'?: number

  @Expose({ name: 'traffic_facility_num' })
  @Type(() => Number)
  'trafficFacilityNum'?: number

  @Expose({ name: 'education_service_num' })
  @Type(() => Number)
  'educationServiceNum'?: number

  @Expose({ name: 'financial_service_num' })
  @Type(() => Number)
  'financialServiceNum'?: number

  @Expose({ name: 'accommodation_service_num' })
  @Type(() => Number)
  'accommodationServiceNum'?: number


  @Expose({ name: 'construction_site_num' })
  @Type(() => Number)
  'constructionSiteNum'?: number


}
