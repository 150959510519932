import { Injectable } from "@angular/core";
import { load } from "@amap/amap-jsapi-loader";
import { API_MAP_KEY, MAP_VERSION } from "@app/constants";

@Injectable({
  providedIn: 'root',
})
export class MapLoaderService {
  constructor(

  ) { }

  load(): Promise<any> {

    return load({
      version: MAP_VERSION,
      key: API_MAP_KEY,
      Loca: {
        version: '1.3.2',
      },
      plugins: ['AMap.DistrictSearch', 'AMap.Driving', 'AMap.Geolocation', 'AMap.Polygon', 'AMap.PolyEditor',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Geocoder',]
    }).then(AMap => {
      return new Promise<void>((resolve, reject) => {
        resolve(AMap)
      })
    })

  }
}

