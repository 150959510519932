
import { Expose, Type } from 'class-transformer'
import { TrafficsBase } from '../../types'


export class AreaTrafficDto {
  @Expose()
  @Type(() => TrafficsBase)
  items: TrafficsBase[]
}
