import { NavController } from '@ionic/angular'


export function navLength(nav: NavController) {
  const nava = nav as any
  if (nava?.topOutlet?.stackCtrl?.views) {
    return nava.topOutlet.stackCtrl.views.length
  }
  return 0
}
