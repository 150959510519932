import { Injectable } from '@angular/core'
import { APIService } from '@app/core/services/api'
import { plainToClass } from 'class-transformer'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { ResponseType } from '../types'
import {
  GetFixedStationDto,
  GetFixedStationHourAirQualityDto,
  GetMobileRealDto,
  GetMobileStationQualityDto,
  StationForecastDto,
  StationWeatherDto,
} from './dto'
import { GetFixedStationHourAirQualityParams, MobileStationAirQualityParams, StationFeatureParams, StationForecastParams } from './params'
import { GetFixedStationParams } from './params/get-fixed-station.params'


@Injectable({
  providedIn: 'root',
})

export class SiteService extends APIService {

  // 获取固定站点小时空气质量数据
  getFixedStationHourAirQuality(getFixedStationHourAirQualityParams: GetFixedStationHourAirQualityParams, latest = false) {
    const path = latest ? 'latest-hour-air-quality' : 'hour-air-quality'
    return this.http.post<any>(`${environment.baseURL}/platform-alpha-statistical/fixed-station/${path}`,
      getFixedStationHourAirQualityParams.toJsonParams()).pipe(map(res => {
        return plainToClass(GetFixedStationHourAirQualityDto, res.data, { excludeExtraneousValues: true })
      }),
        catchError(this.handleError))
  }

  getFixedStationLatestHour() {
    return this.http.get<ResponseType<{ latestPublishedAt: string }>>
      (`${environment.baseURL}/platform-alpha-statistical/fixed-station/latest-hour-air-quality`, {
        params: {
          page: '1',
          pageSize: '1',
        },
      }).pipe(
        catchError(this.handleError),
        map(res => res.data.latestPublishedAt),
      )
  }

  getFixedStation(params: GetFixedStationParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/fixed-station`, {
      params: params.bracketParams(),
    }).pipe(
      map(res => plainToClass(GetFixedStationDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getFixedHourForecast(params: StationForecastParams) {
    return this.http.get<any>(`${environment.baseURL}/alpha-forecast/station/forecast-hour-air-quality`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(StationForecastDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getHourWeather(params: StationFeatureParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/station/hour-weather`, {
      params: params.bracketParams(),
    }).pipe(
      map(res => plainToClass(StationWeatherDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getMobileRealAirQuality(params: MobileStationAirQualityParams) {
    return this.http.post<ResponseType<GetMobileRealDto>>(`${environment.baseURL}/platform-alpha-statistical/mobile-station/realtime-air-quality`,
      params.toJsonParams()).pipe(
        // map(res => plainToClass(GetMobileRealDto, res, { excludeExtraneousValues: true })),
        // map(res => res),
        catchError(this.handleError),
      )
  }

  getMobileLastLocation(params: MobileStationAirQualityParams) {
    return this.http.get<GetMobileStationQualityDto>(`${this.coreCofnig.baseURL}/mobile-station/last-coord-before-time`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GetMobileStationQualityDto, res, { excludeExtraneousValues: true })),
      // map(res => res),
      catchError(this.handleError),
    )
  }

  getFixedInArea(areaId: number) {
    return this.http.get<GetFixedStationDto>(`${this.coreCofnig.baseURL}/area/fixed-station/${areaId}`, {
    }).pipe(
      map(res => plainToClass(GetFixedStationDto, res, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  //查询用户关注站点列表
  getAttentionStationList(params: GetFixedStationParams) {
    return this.http.post<any>(`${environment.baseURL}/platform-alpha-statistical/stationDistribute/getStationList`,
      params)
      .pipe(
        catchError(this.handleError),
      )
  }

  //查询站点详情
  getAttentionStationHourAirQuality(params: GetFixedStationHourAirQualityParams) {
    return this.http.post<any>(`${environment.baseURL}/platform-alpha-statistical/fixed-station/hour-air-quality`,
      params)
  }

  //获取空气质量数据
  getHourAirQuality(params: any) {
    return this.http.post<any>(`${environment.baseURL}/platform-alpha-statistical/fixed-station/hour-air-quality`,
      params)
  }
}
