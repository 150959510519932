// import { URL_SESSION } from '@app/constants'

// const url = JSON.parse(localStorage.getItem(URL_SESSION))
// const baseURL = url ? url.baseUrl : 'http://192.168.2.78:8083'

const baseURL = 'https://server.glzt.com.cn'

const Config = {
  baseURL,
}

export { Config }
