import { Injectable } from '@angular/core'
import { StorageSync } from './storage_sync.decorator'
// import { TOEKN_NAME } from '@dv/constants'
// import { StorageSync } from '@dv/shared/utils/storage_sync.decorator'

const TOEKN_NAME = 'AM_WORK_MOBILE_TOKEN'
const REFRESH_KEY = 'AM_WORK_MOBILE_REFRESH_KEY'
const TOKEN_TIME = 'AM_WORK_MOBILE_TOKEN_TIME'
const TOKEN_EXPIRE_KEY = 'AM_WORK_MOBILE_TOKEN_EXPIRE_KEY'

@Injectable({
  providedIn: 'root',
})
export class TokenManager {

  @StorageSync(TOEKN_NAME)
  private _token: string

  @StorageSync(REFRESH_KEY)
  private _refreshToken: string

  @StorageSync(TOKEN_EXPIRE_KEY)
  private _expireTime: number

  @StorageSync(TOKEN_TIME, {
    transformOut: (str) => new Date(str),
  })
  private _refreshTime: Date

  constructor(
  ) {
  }

  get() {
    return this._token
  }

  set(tokens: {
    token: string,
    refresh_token: string,
    expires_in: number,
  }) {
    this._token = tokens?.token

    this._refreshToken = tokens?.refresh_token
    this._expireTime = tokens?.expires_in
    this._refreshTime = new Date()
  }

  get refreshToken() {
    return this._refreshToken
  }

  // 即将过期：还差1个小时就过期
  get willExpire() {
    const endTimeVal = this._expireTime * 1000 - 60* 60 *1000

    if (new Date().valueOf() >  this._refreshTime.valueOf() + endTimeVal) {

      return true
    }

    return false

  }


}
