import { Expose, Type } from 'class-transformer'

export class Page {
  @Expose()
  @Type(() => Number)
  total: number

  @Expose({ name: 'current' })
  @Type(() => Number)
  currentPage: number

  @Expose({ name: 'per_page' })
  @Type(() => Number)
  perPage: number
}
