/*
 * @Author: your name
 * @Date: 2021-08-06 17:44:34
 * @LastEditTime: 2022-03-21 10:13:29
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \task-dispatch-mobile\src\app\app-routing.module.ts
 */
import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { CanActivateService } from './core'

// import { CanActivateService } from '@app/core/services'

const routes: Routes = [
  {
    path: 'tabs',
    canActivate: [CanActivateService],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'events',
    canActivate: [CanActivateService],
    loadChildren: () => import('./pages/events/event.module').then(m => m.EventModule),
  },
  {
    path: 'special-events',
    canActivate: [CanActivateService],
    loadChildren: () => import('./pages/events/pages/special-events/specialEvent.module').then(m => m.SpecialEventModule),
  },
  {
    path: 'distribute',
    canActivate: [CanActivateService],
    loadChildren: () => import('./pages/distribute/distribute.module').then(m => m.DistributeModule),
  },
  {
    path: 'pollute',
    canActivate: [CanActivateService],
    loadChildren: () => import('./pages/pollute/pollute.module').then(m => m.PolluteModule),
  },
  {
    path: 'redirect',
    loadChildren: () => import('./pages/redirect/redirect.module').then(m => m.RedirectPageModule),
  },
  {
    path: 'no-permission',
    loadChildren: () => import('./pages/no-permission/no-permission.module').then( m => m.NoPermissionPageModule),
  },
  {
    path: 'point-nav',
    loadChildren: () => import('./pages/point-nav/point-nav.module').then(m => m.PointNavModule),
  },

  {
    path: '**',
    redirectTo: 'tabs',
  },
]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
