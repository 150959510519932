import { Injectable } from '@angular/core'
import { plainToClass } from 'class-transformer'
import { catchError, map } from 'rxjs/operators'

import { APIService } from '@app/core/services/api'
import { environment } from 'src/environments/environment'
import { StaticFeatureBase } from '../types'
import { AreaHourAirQualityDtoLq, AreaTrafficDto, AreaWeatherFeatureDto } from './dto'
import { GetAreaListDto } from './dto/get-area-list.dto'
import { AreaHourAirQualityParamsLq } from './model'
import { AreaListParams } from './params'


@Injectable({
  providedIn: 'root',
})

// 区域网格模块
export class AreaService extends APIService {

  // 获取区域小时空气质量
  getAreaHourAirQuality(areaHourAirQualityParams: AreaHourAirQualityParamsLq) {
    return this.http.post<any>(`${environment.baseURL}/platform-alpha-statistical/area/hour-air-quality`, areaHourAirQualityParams.toJsonParams())
    .pipe(map(res => {
      return plainToClass(AreaHourAirQualityDtoLq, res.data, { excludeExtraneousValues: true })
    }),
      catchError(this.handleError),
    )
  }

  getHourWeather(areaId: number, params: { publishedAtBegin: string, publishedAtEnd: string }) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/area/${areaId}/weather/hour`, {
      params,
    }).pipe(
      map(res => plainToClass(AreaWeatherFeatureDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getHourTraffic(areaId: number, params: { publishedAtBegin: string, publishedAtEnd: string }) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/area/${areaId}/traffic/hour`, {
      params,
    }).pipe(
      map(res => plainToClass(AreaTrafficDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  getAreaList(paramms: AreaListParams) {
    return this.http.get<GetAreaListDto>(`${this.coreCofnig.baseURL}/areas`, {
      params: paramms.toHttpParams(),
    }).pipe(map(res => {
      return plainToClass(GetAreaListDto, res, { excludeExtraneousValues: true })
    }),
      catchError(this.handleError),
    )
  }

  getStaticFeature(areaId: number) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/area/static-featurer/${areaId}`).pipe(
      map(res => plainToClass(StaticFeatureBase, res.data?.items?.[0], { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

}
