import { Expose, Type } from 'class-transformer'


import { ItemList, Meta } from '@app/core/services/types'
import { GetAreaListItem } from '../model'

export class GetAreaListDto implements ItemList<GetAreaListItem> {
  @Expose()
  @Type(() => GetAreaListItem)
  items: GetAreaListItem[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
