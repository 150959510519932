import { AirBase, Coord } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'
import { parse } from 'date-fns'

export class GridDayAirQualitiesBase {
  @Expose()
  @Type(() => Number)
  'id': number

  @Expose()
  @Type(() => Number)
  'gridId': number

  @Expose()
  @Type(() => Number)
  'gridAreaIds': number[]

  @Expose()
  @Type(() => String)
  'date': string

  @Expose()
  @Type(() => String)
  'gridTags': string[]

  @Expose()
  @Type(() => AirBase)
  'data': AirBase

  @Expose()
  @Type(() => String)
  'gridCode': string

  @Expose()
  @Transform(v => {
    return parse(v, 'yyyy-MM-dd HH:mm:ss', new Date())
  }, { toClassOnly: true })
  publishedAt: Date

  @Expose()
  location: string

  @Expose()
  @Type(() => Coord)
  centerCoord: Coord

  area: string
}
