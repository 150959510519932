export enum TaskStatus {
  normal,
  revoked,
  fallbacked,
  received,
  checked,
  unchecked,
  feedbacked,
  toBeReceived,
  waitReview,
  beRejected,
  rejected,
  submitted,
  expired,
  frozen,
}


export enum TaskLifeStatus {
  recived = 'pushed', // 已接收
  returned = 'returned',
  overdue = 'oversaw', // 逾期
  rejected = 'rejected',
  terminated = 'terminated',
  distributed = 'distributed',
  feedbacked = 'feedbacked',
  canceled = 'invalid', // 已作废
  be_rejected = 'beRejected', // 被驳回
  withdrew = 'withdrew', // 已撤回\\
  expired = 'expired',
  frozen = 'frozen',// 已冻结
}

export const TASK_LIFE_STATUS_LIST = [
  TaskLifeStatus.recived,
  TaskLifeStatus.returned,
  TaskLifeStatus.overdue,
  TaskLifeStatus.rejected,
  TaskLifeStatus.terminated,
  TaskLifeStatus.distributed,
  TaskLifeStatus.feedbacked,
  TaskLifeStatus.canceled,
  TaskLifeStatus.be_rejected,
  TaskLifeStatus.withdrew,
]

export function taskLifeStatusName(status: TaskLifeStatus) {
  const mapping = {
    [TaskLifeStatus.recived]: '已接收',
    [TaskLifeStatus.overdue]: '已逾期',
    [TaskLifeStatus.returned]: '已退回',
    [TaskLifeStatus.rejected]: '已驳回',
    [TaskLifeStatus.terminated]: '已终止',
    [TaskLifeStatus.distributed]: '已派发',
    [TaskLifeStatus.feedbacked]: '已反馈',
    [TaskLifeStatus.canceled]: '已作废',
    [TaskLifeStatus.be_rejected]: '被驳回',
    [TaskLifeStatus.withdrew]: '已撤回',
    [TaskLifeStatus.expired]: '已失效',
    [TaskLifeStatus.frozen]: '已冻结',
  }
  return mapping[status] || '未知状态'
}

export function taskStatusName(status: TaskStatus) {
  const mapping = {
    [TaskStatus.normal]: '待接收',
    [TaskStatus.revoked]: '已撤销',
    [TaskStatus.fallbacked]: '已退回',
    [TaskStatus.received]: '已接收',
    [TaskStatus.toBeReceived]: '待接收',
    [TaskStatus.checked]: '已排查',
    [TaskStatus.unchecked]: '待排查',
    [TaskStatus.feedbacked]: '已办结',

  }
  return mapping[status] || '未知状态'
}

export function checkStatusName(status: TaskStatus) {
  if (status === TaskStatus.checked) {
    return '已排查'
  }
  return '未排查'
}

export enum TaskAction {
  feedback,
  distribute,
  down_diliver,
  reject,
  view,
  recall,
  cancel,
  urge,
}

export function taskActionName(action: TaskAction) {
  return {
    [TaskAction.feedback]: '反馈',
    [TaskAction.distribute]: '派发',
    [TaskAction.down_diliver]: '下发',
    [TaskAction.reject]: '驳回',
    [TaskAction.view]: '查看',
    [TaskAction.recall]: '撤回',
    [TaskAction.cancel]: '作废',
    [TaskAction.urge]: '催办',
  }[action]
}

export const EVENT_TYPE_MAP = [
  { key: '', title: '不限' },
  { key: 'XC', title: '巡查上报' },
  { key: 'TS', title: '投诉上报' },
  { key: 'WG', title: '网格告警' },
  { key: 'ZD', title: '站点告警' },
  { key: 'WJ', title: '大数据挖掘' },
  { key: 'QP', title: '移动监测' },

]
export const POLLUTION_TYPE = [
  { key: 'constructionSitePollution', title: '工地' },
  { key: 'factoryEmission', title: '企业' },
  { key: 'trafficFumes', title: '交通尾气' },
  { key: 'trafficDust', title: '交通扬尘' },
  { key: 'other', title: '其他' },

]

export const SOURCR_TYPE = [
  { key: 'constructionSite', title: '工地' },
  { key: 'factory', title: '企业' },
  { key: 'road', title: '尾气排放' },
  { key: 'trafficDust', title: '交通扬尘' },
  { key: 'truck', title: '运渣车' },
  { key: 'traffic', title: '交通' },
  { key: 'truckEntryPoint', title: '运渣车驻点' },
  { key: 'other', title: '其他' },

]
export const TASK_LIFE_STATUS_LIST2 = [
  { key: 'toBeReceived', title: '待接收', color: 'warn' },
  { key: 'wait', title: '待派发', color: 'warn' },
  // { key: 'withdrew', title: '已撤回', color: 'warn' },
  { key: 'rejected', title: '已撤回', color: 'warn' },
  { key: 'feedbacked', title: '已办结', color: 'complete' },
  { key: 'submitted', title: '已提交', color: 'warn' },
  { key: 'waitCheck', title: '待排查', color: 'warn' },
  { key: 'distributed', title: '已派发', color: 'warn' },
  { key: 'pushed', title: '已推送', color: 'warn' },
  { key: 'toBeReviewed', title: '待复核', color: 'warn' },
  { key: 'beRejected', title: '被撤回', color: 'warn' },
  { key: 'expired', title: '已失效', color: 'gray' },
  { key: 'voided', title: '已作废', color: 'gray' },
  { key: 'frozen', title: '已冻结', color: 'gray' },



]
// 污染管理 申请状态
export const POLLUTE_LIFE_STATUS_LIST = [

  { key: 'passed', title: '已通过', color: '#0BD976' },
  { key: 'toBeReviewed', title: '审核中', color: '#FFDD00' },
  { key: 'rejected', title: '已驳回', color: '#FF9933' },
  { key: 'application', title: '已申请', color: '#0A84FF' },
]

// 审核状态
export const AUDIT_STATUS=[
  { key: '', title: '全部', color: '#0BD976' },
  { key: 'passed', title: '已通过', color: '#0BD976' },
  { key: 'examining', title: '审核中', color: '#FFDD00' },
  { key: 'rejected', title: '已驳回', color: '#FF9933' },
  { key: 'pending', title: '待审核', color: '#0A84FF' },
]
// 污染源状态
export const POLLUTION_SOURCE_STATUS = [
  { label: '待排查', value: 'wait' , color: 'warn'},
  { label: '存在', value: 'existence' , color: ''},
  { label: '不存在', value: 'not_existence', color: '' },
]


export const POLLUTION_SOURCE_ICON =[
  {key: 'construction_site',label: '工地',icon:'assets/common/construction_site.svg'},
  {key: 'factory',label: '工厂',icon:'assets/common/factory.svg'},
  {key: 'gas_station',label: '加油站',icon:'assets/common/gas_station.svg'},
  {key: 'domestic_wastes',label: '生活垃圾',icon:'assets/common/domestic_wastes.svg'},
  {key: 'car_repair',label: '汽车修理',icon:'assets/common/car_repair.svg'},
  {key: 'husbandry_breed',label: '畜牧养殖',icon:'assets/common/husbandry_breed.svg'},
  {key: 'parking',label: '停车场',icon:'assets/common/parking.svg'},
  {key: 'garbage_sewage',label: '垃圾污水处理',icon:'assets/common/garbage_sewage.svg'},
  {key: 'restaurant',label: '餐饮',icon:'assets/common/restaurant.svg'},
  {key: 'dumping_ground',label: '倾倒场',icon:'assets/common/qindao.svg'},


  {key: 'road',label: '交通尾气排放',icon:'assets/common/traffic.svg'},
  {key: 'road_dust',label: '交通扬尘污染',icon:'assets/common/dust.svg'},
  {key: 'unorganized_emission',label: '无组织排放(秸秆焚烧)',icon:'assets/common/other.svg'},
  {key: 'truck_entry_point',label: '驻点',icon:'assets/common/location.svg'},
  {key: 'truck',label: '运渣车',icon:'assets/common/source-icon-truck.svg'},
  {key: 'other',label: '其他',icon:'assets/common/other.svg'},
]

export const  STATION_ICON =[
  {key: 'nation_ctrl_station',label: '国控站点',icon:'assets/icon/site/fix-ncs.svg'},
  {key: 'province_ctrl_station',label: '省控站点',icon:'assets/icon/site/fix-pcs.svg'},
  {key: 'city_ctrl_station',label: '市控站点',icon:'assets/icon/site/fix-ccs.svg'},
  {key: 'district_ctrl_station',label: '区控站点',icon:'assets/icon/site/fix-dcs.svg'},
  {key: 'tvoc_station',label: 'TVOC站点',icon:'assets/icon/site/TVOC.svg'},
]

// 同步状态
export const SYNC_STATUS = [
  {
    key: '',
    label: '全部',
    name: '全部',
  },
  {
    key: '0',
    label: '待同步',
    name: '待同步',
  },
  {
    key: '1',
    label: '同步',
    name: '同步',
  },
]
// 变更类型
export const CHANGE_TYPE = [
  {
    key: '',
    label: '全部',
    name: '全部',
  },
  {
    key: 'enable',
    label: '启用',
    name: '启用',
  },
  {
    key: 'disable',
    label: '禁用',
    name: '禁用',
  },
  {
    key: 'change',
    label: '变更',
    name: '变更',
  },
  {
    key: 'add',
    label: '新增',
    name: '新增',
  },
]
// 排查信息
export const BARE_AREA = [
  { key: 1, title: '未按规定执行部分裸土未覆盖' },
  { key: 2, title: '大面积未覆盖' },
  { key: 3, title: '符合' },

]
export const CAR = [
  { key: 1, title: '不符合' },
  { key: 2, title: '符合规定 ' },
]
export const SPRAY = [
  { key: 0, title: '开启' },
  { key: 1, title: '未开启' },
]
export const CAR_DIRTY = [
  { key: 1, title: '车身较脏' },
  { key: 2, title: '无车身较脏问题' },
]
export const OTHER_THING = [
  { key: 0, title: '未按规定执行' },
  { key: 1, title: '符合规定' },
]
export const POLLUTION_WEATHER = [
  { key: 0, title: '未按规定执行' },
  { key: 1, title: '符合规定' },
]
export const PROBLEM_TYPE = [

  { key: 'constructionSitePollution', title: '工地扬尘污染' },
  { key: 'factoryEmission', title: '企业废气排放' },
  { key: 'trafficFumes', title: '交通尾气排放' },
  { key: 'trafficDust', title: '交通扬尘污染' },
  { key: 'other', title: '其他' },
]
export const UPDATED = [
  { key: 1, title: '否' },
  { key: 0, title: '是' },

]
