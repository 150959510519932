import { Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { FixedStationBase } from '../model'


export class GetFixedStationDto {
  @Expose()
  @Type(() => FixedStationBase)
  items: FixedStationBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
