import { Expose, Transform, Type } from 'class-transformer'
import { AreaAirQualityLqBase } from './area-air-quality.base'

export class AreaHourAirQualityBaseLq {
  @Expose()
  @Type(() => Number)
  id: number

  @Expose()
  @Type(() => String)
  source: string

  @Expose()
  @Type(() => AreaAirQualityLqBase)
  data: AreaAirQualityLqBase

  @Expose()
  @Type(() => String)
  publishedAt: string

  @Expose()
  @Type(() => Number)
  areaId: number

  @Expose()
  @Type(() => String)
  areaCode: string

  @Expose()
  @Type(() => String)
  areaName: string

  @Expose()
  @Type(() => String)
  areaType: string
}
