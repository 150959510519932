import { HttpParams } from '@angular/common/http'
import { classToPlain } from 'class-transformer'
import { pickBy } from 'lodash'
import { stringify } from 'qs'

// @dynamic
export class ParamsPayload {
  constructor() { }

  protected initParams(params: { [key: string]: any }) {
    Object.keys(params)
      .forEach(key => {
        this[key] = params[key]
      })
  }

  toHttpParams() {
    const params = {}
    const plain = classToPlain(this, { excludeExtraneousValues: true })

    Object.keys(plain)
      .filter(key => {
        return plain[key] !== undefined
      })
      .forEach(key => {
        params[key] = plain[key]
      })

    // console.log('params', params)

    return new HttpParams({ fromObject: params })
  }

  toJsonParams() {
    const params = {}
    const plain = classToPlain(this, { excludeExtraneousValues: true })

    Object.keys(plain)
      .filter(key => {
        return plain[key] !== undefined
      })
      .forEach(key => {
        params[key] = plain[key]
      })
    return params
  }

  bracketParams() {
    const params: Record<string, any> = {}
    const plain = classToPlain(this, { excludeExtraneousValues: true })

    Object.keys(plain)
      .filter(key => {
        return plain[key] !== undefined
      })
      .forEach(key => {
        params[key] = plain[key]
      })
    return new HttpParams({
      fromString: stringify(pickBy(params, (v) => v != null), { arrayFormat: 'brackets' }),
    })
  }
}
