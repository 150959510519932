import { Coord, IndexModel } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'


export class GridForecastBase {
  @Expose()
  @Type(() => Number)
  'id'?: number

  @Expose()
  @Type(() => Number)
  'gridId'?: number

  @Expose()
  @Type(() => String)
  'gridCode'?: string

  @Expose()
  @Type(() => Number)
  'gridRow'?: number

  @Expose()
  @Type(() => Number)
  'gridColumn'?: number

  @Expose()
  @Type(() => Boolean)
  'isOwned'?: boolean

  @Expose()
  @Type(() => Coord)
  'centerCoord'?: Coord

  @Expose()
  @Type(() => IndexModel)
  'data'?: IndexModel

  @Expose()
  @Type(() => Date)
  'publishedAt'?: Date

  @Expose()
  @Type(() => Date)
  'forecastAt'?: Date
}
