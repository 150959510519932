

export function StorageSync(syncKey?: string, options?: {
  transformIn?: (val: any) => string,
  transformOut?: (str: string) => any,
}): PropertyDecorator {

  return (target, property) => {
    if (!syncKey) {
      syncKey = target.constructor.name + property.toString()
    }
    let val = target[property]
    Object.defineProperty(target, property, {
      get() {
        if (val == null) {
          val = window.localStorage.getItem(syncKey) || null
          if (val && options?.transformOut) {
            val = options.transformOut(val)
          }
        }
        return val
      },
      set(newVal) {
        val = newVal
        if (!val) {
          window.localStorage.removeItem(syncKey)
        } else {
          if (options?.transformIn) {
            const transformVal = options.transformIn(val)
            window.localStorage.setItem(syncKey, transformVal)
          } else {
            window.localStorage.setItem(syncKey, val)
          }
        }
      },
    })
  }
}
