import { Coord } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'


export class GridFeature {
  @Expose()
  data: any

  @Expose({ name: 'created_at' })
  createdAt: string

  @Expose({ name: 'grid.area_ids' })
  gridAreaIds: number[]

  @Expose({ name: 'grid_code' })
  gridCode: string

  @Expose({ name: 'updated_at' })
  updatedAt: string

  @Expose({ name: 'center_coord' })
  @Type(() => Coord)
  centerCoord: Coord

  @Expose({ name: 'grid_id' })
  gridId: number

  @Expose({ name: 'location' })
  location: string

  @Expose()
  id: number
}
