import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'

export class StationForecastParams extends PageParamsPayload {

  @Expose({ name: 'forecastAtBegin' })
  @Type(() => String)
  forecastAtBegin?: string

  @Expose({ name: 'forecastAtEnd' })
  @Type(() => String)
  forecastAtEnd?: string

  @Expose({ name: 'stationCode' })
  @Type(() => String)
  stationCode?: string

  @Expose({ name: 'publishedAtBegin' })
  @Type(() => String)
  publishedAtBegin?: string

  @Expose({ name: 'publishedAtEnd' })
  @Type(() => String)
  publishedAtEnd?: string

  @Expose({ name: 'timeStep' })
  @Type(() => Number)
  futureHour?: number


  constructor(params: {
    forecastAtBegin?: string,
    forecastAtEnd?: string,
    stationCode?: string,
    publishedAtBegin?: string,
    publishedAtEnd?: string,
    futureHour?: number,
    sortAsc?: string,
    sortDesc?: string,
  }) {
    super()
    this.initParams(params)
  }
}
