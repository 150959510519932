import { direDict } from './grid-dire-dict'
import GridType from './grid-type'
/* tslint:disable */
export default class GridEdge {
  grid: GridType
  dire: number
  start: [number, number]
  end: [number, number]
  constructor(grid: GridType, dire: number) {
    this.grid = grid
    this.dire = dire
    this.init()
  }

  init() {
    let start
    let end

    // 顺时针 开始到结束
    if (this.dire === direDict.top) {
      start = this.getPoi(this.grid, direDict.top | direDict.left)
      end = this.getPoi(this.grid, direDict.top | direDict.right)
    } else if (this.dire === direDict.bottom) {
      end = this.getPoi(this.grid, direDict.bottom | direDict.left)
      start = this.getPoi(this.grid, direDict.bottom | direDict.right)
    } else if (this.dire === direDict.left) {
      end = this.getPoi(this.grid, direDict.top | direDict.left)
      start = this.getPoi(this.grid, direDict.bottom | direDict.left)
    } else if (this.dire === direDict.right) {
      start = this.getPoi(this.grid, direDict.top | direDict.right)
      end = this.getPoi(this.grid, direDict.bottom | direDict.right)
    }

    this.start = start
    this.end = end
  }

  getPoi(grid: GridType, dire: number): [number, number] {
    let lat
    let lon
    if (dire === (direDict.top | direDict.right)) {
      lon = grid.topRightCoord.lng
      lat = grid.topRightCoord.lat
    }
    if (dire === (direDict.top | direDict.left)) {
      lon = grid.bottomLeftCoord.lng
      lat = grid.topRightCoord.lat
    }

    if (dire === (direDict.bottom | direDict.right)) {
      lon = grid.topRightCoord.lng
      lat = grid.bottomLeftCoord.lat
    }
    if (dire === (direDict.bottom | direDict.left)) {
      lon = grid.bottomLeftCoord.lng
      lat = grid.bottomLeftCoord.lat
    }

    return [lon, lat]
  }

}
