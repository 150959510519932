import { Expose, Type } from 'class-transformer'

export class StationWeatherData {


  @Expose()
  @Type(() => Number)
  temp: number

  @Expose()
  @Type(() => Number)
  humidity: number

  @Expose()
  @Type(() => Number)
  pressure: number

  @Expose()
  @Type(() => Number)
  rainfall: number

  @Expose()
  @Type(() => Number)
  visibility: number

  @Expose({ name: 'wind_speed' })
  @Type(() => Number)
  windSpeed: number

  @Expose({ name: 'cloud_amount' })
  @Type(() => Number)
  cloudAmount: number

  @Expose({ name: 'weather_code' })
  @Type(() => String)
  weatherCode: string

  @Expose({ name: 'wind_degrees' })
  @Type(() => Number)
  windDegrees: number

}
