import { ItemList, Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { AreaAirQualityBase } from '../../area/model/area-air-quality.base'


export class GlobalAirQualityDto implements ItemList<AreaAirQualityBase> {


  @Expose()
  @Type(() => AreaAirQualityBase)
  items: AreaAirQualityBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta

}
