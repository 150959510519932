import {
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { of, throwError, Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import * as Sentry from "@sentry/angular"
import { Severity } from '@sentry/angular'
import { TokenManager } from '..'
import { environment } from 'src/environments/environment'
@Injectable()
export class ResponseSuccessInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private token: TokenManager,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(next)
    return next.handle(req).pipe(
      catchError(err => {

        if (err instanceof HttpErrorResponse && environment.production) {
          try {
            Sentry.captureMessage(JSON.stringify(req.body), {
              level: Severity.Log,
              user: {
                id: this.token.get(),
              },
            })
            Sentry.captureMessage(JSON.stringify(err.error), {
              level: Severity.Log,
              user: {
                id: this.token.get(),
              },
            })
          } catch (e) {

          }
        }
        if (err.status === 401) {
          if (this.router.url !== '/login') {
            this.router.navigate(['/login'])
          }
          return throwError(err)
        }
        return throwError(err)
      }),
    )
  }
}
