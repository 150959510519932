import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { NavController } from '@ionic/angular'
import { filter } from 'rxjs/operators'
import { AuthService, WxService } from './core'
import { HistoryService } from './core/services/history'
import { isWeixin } from './shared/utils'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(


    private wx: WxService,
    private auth: AuthService,
    private router: NavController,
    private history: HistoryService,
  ) {
    this.initializeApp()

  }

  initializeApp() {



    this.history.lockBack()
    const location = window.location.href
    if (isWeixin()) {
      this.wx.wxEntrance = window.location.href
    }

  }
}
