import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { throwError } from 'rxjs'

import { LoggerService } from '../logger'
import { StorageService } from '../storage'
import { Config } from './requireUrl'

@Injectable({
  providedIn: 'root',
})

export class APIService {
  protected coreCofnig = Config
  constructor(
    protected http: HttpClient,
    protected logger: LoggerService,
    protected router: Router,
  ) { }

  storage = new StorageService()

  message = {
    error: (msg) => console.error(msg),
  }

  protected handleError = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 400:
        this.message.error('请求参数错误')
        break
      case 401:
        this.message.error('账户无权限')
        // this.loginState.clearLoginState()

        break
      case 404:
        this.message.error('未找到')
        break
      case 500:
        this.message.error('服务器错误')
        break
      default:
        this.message.error('请求发生错误')
    }
    if (error.error instanceof ErrorEvent) {
      this.logger.error(error.error.message)
    } else if (error.error && error.error.message) {
      this.logger.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`,
      )
      return throwError(error.error.message)
    } else if (error.error) {
      const message = JSON.stringify(error.error)
      this.logger.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${message}`,
      )
      return throwError(message)
    }

    return throwError('请求发生错误')
  }
}
