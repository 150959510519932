import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CheckItemBase } from './base'
import { ResponseType } from '../types'
import { environment } from 'src/environments/environment'
@Injectable({
    providedIn: 'root',
  })

  export class CheckItemService {

    constructor(private http: HttpClient) {}

      getCheckItem(problemType: string){
        return this.http.post<ResponseType<CheckItemBase>>(`${environment.baseURL}/event-push/findFeedbackTemplate`,{problemType:problemType})
      }
  }
