/*
 * @Author: your name
 * @Date: 2021-08-06 17:44:34
 * @LastEditTime: 2021-08-23 09:49:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \task-dispatch-mobile\src\environments\environment.ts
 */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: true,
  app_version: 'v1.0.0',
  // baseURL: 'http://106.75.97.252:8105',

  baseURL: 'https://pl-soul-h.glzt.com.cn',  // 正式
  websocketURL: 'wss://maps-socket.glzt.com.cn/webSocket',


   // 生产环境的文件上传
  bucketName:'pro-app-mobile',
  publicKey:'TOKEN_92ae9bf3-0044-4ca9-adf5-23c619d6b192',
  privateKey:'8b747335-283d-4eac-8380-20d6a864d3d5',
  bucketUrl:'https://pro-app-mobile.cn-bj.ufileos.com',

  // 测试环境的文件上传
  // bucketName:'glzt',
  // publicKey:'TOKEN_2b89fbbc-74da-4ee8-80d2-558d53144fee',
  // privateKey:'30d8fe50-f96e-42b9-b4f4-1bb69f7cfe57',
  // bucketUrl:'https://glzt.cn-bj.ufileos.com',

  // baseURL: 'http://192.168.4.232:9195',


  //  baseURL: 'http://192.168.4.232:9195',  // 开发2
  // baseURL: 'http://106.75.97.252:8105',  //正式
  // baseURL: 'http://t-soul-h.glzt.com.cn', // 暂时迁移网关
  //  baseURL: 'https://t-pl-soul.glzt.com.cn',  // 测试
  //  baseURL: 'http://t-soul.glzt.com.cn',  // 正式





  // 测试环境的文件上传-Ucloud
  // bucketName:'glzt',
  // publicKey:'TOKEN_2b89fbbc-74da-4ee8-80d2-558d53144fee',
  // privateKey:'30d8fe50-f96e-42b9-b4f4-1bb69f7cfe57',
  // bucketUrl:'https://glzt.cn-bj.ufileos.com',



// 测试环境的文件上传-华为云obs
  // ak: 'VWHOXZA8BNDRO52ASDZP',
  // sk: 'cPwuq21cHQZJul2dnOKKRhFuX8WuYZpl06gAmuDA',
  // userName: 'test-glzt-obs',
  // endPoint: 'obs.cn-south-1.myhuaweicloud.com',
  // obsBucketName: 'test-glzt',
  // obsName: 'test-glzt-obs.glzt.com.cn/',
  // parentDir: 'platform/',

  // 生产环境的文件上传-华为云obs
  ak: 'SOV7GCQD5LXBIH3M2GTJ',
  sk: '3d5Neehf7n5ztkryRz3UtaHiU7EWWrjGxT2tlHFf',
  userName: 'pro-glzt-obs',
  endPoint: 'obs.cn-south-1.myhuaweicloud.com',
  obsBucketName: 'pro-glzt-platform',
  obsName: 'pro-glzt-obs.glzt.com.cn/',
  parentDir: 'pollute/',

  // 生产环境的文件上传
  // bucketName:'pro-app-mobile',
  // publicKey:'TOKEN_92ae9bf3-0044-4ca9-adf5-23c619d6b192',
  // privateKey:'8b747335-283d-4eac-8380-20d6a864d3d5',
  // bucketUrl:'https://pro-app-mobile.cn-bj.ufileos.com',

  preBaseURL: 'https://server.glzt.com.cn',
  preWebsocketURL: 'wss://amqp.glzt.com.cn:12674/ws',

  sentry: 'production',
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
