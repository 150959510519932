import { PageParamsPayload } from '@app/core/services/types'
import { Expose } from 'class-transformer'


export class FindGridParams extends PageParamsPayload {

  @Expose({ name: 'gridCode' })
  gridCode: string

  @Expose({ name: 'id' })
  gridId: string

  @Expose()
  location: string

  @Expose({ name: 'areaId' })
  areaId: string

  @Expose({ name: 'isOwned' })
  isOwned: boolean

  constructor(params?: {
    gridCode?: string,
    location?: string,
    areaId?: string,
    gridId?: number,
    isOwned?: boolean,
    pageSize?: number,
    page?: number,
  }) {
    super()
    this.initParams(params)
  }
}
