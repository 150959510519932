import { Injectable } from '@angular/core'
import { APIService } from '@app/core/services/api'
import { plainToClass } from 'class-transformer'
import { catchError, map, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { AreaAirQualityBase } from '../area'
import { AreaStaticFeature, ResponseType, StaticFeatureBase, TrafficsBase, WeatherBase } from '../types'
import { ClientBase } from './base/area.base'
import { AreaForecastDayAirQualityDtoLq, AreaHourTrafficDto, AreaHourWeatherDto, GlobalAirQualityDto } from './dto'
import { AreaHourWeatherBaseData } from './model'
import {
  AreaForecastDayAirQualityParams,
  AreaHourTrafficParams,
  AreaHourWeatherParams,
  AreaStaticFeatureParams,
  GlobalAirQualityParams,
} from './params'

@Injectable({
  providedIn: 'root',
})
export class GlobalService extends APIService {
  // 全域小时天气
  getGlobalHourWeather(getAreaHourWeatherParams: AreaHourWeatherParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/wholeArea/getWholeHourWeatherByPubishedAt`, {
      params: getAreaHourWeatherParams.toHttpParams(),
    }).pipe(map(res => {
      return plainToClass(AreaHourWeatherBaseData, res.data, { excludeExtraneousValues: true })
    }))
  }

  /**
   * 全域静态特征
   */
  getStaticFeature(params?: any) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/wholeArea/getGridStaticFeature`).pipe(
      tap(res => res.data = plainToClass(StaticFeatureBase, res.data, { excludeExtraneousValues: true })),
    )
  }

  /**
   * 全域小时交通
   */
  getGlobalHourTraffic(getAreaHourTrafficParams: AreaHourTrafficParams) {
    return this.http.get<ResponseType<AreaHourTrafficDto>>(`${environment.baseURL}/platform-alpha-statistical/wholeArea/getWholeHourTrafficByPubishedAt`, {
      params: getAreaHourTrafficParams.toHttpParams(),
    }).pipe(map(res => {
      return plainToClass(TrafficsBase, res.data, { excludeExtraneousValues: true })
    }))
  }

  // 全域小时空气质量
  getGlobalHourAirQuality(params: GlobalAirQualityParams) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/wholeArea/getWholeAreaHourAirQuality`, {
      params: params.toHttpParams(),
    }).pipe(
      map(res => plainToClass(GlobalAirQualityDto, res.data, { excludeExtraneousValues: true })),
      catchError(this.handleError),
    )
  }

  // 最新全域小时空气质量-标准
  getGlobalHourAirQualityLatest() {
    return this.http.get<any>(
      `${this.coreCofnig.baseURL}/whole-area/monitoring/latest-hour-air-quality`).pipe(map(res => {
        return plainToClass(AreaAirQualityBase, res.data, { excludeExtraneousValues: true })
      }),
        catchError(this.handleError),
      )
  }

  // 最新全域小时空气质量-am
  getGlobalHourAirQualityLatestAm() {
    return this.http.get<AreaAirQualityBase>(
      `${this.coreCofnig.baseURL}/whole-area/inference/latest-hour-air-quality`).pipe(map(res => {
        return plainToClass(AreaAirQualityBase, res, { excludeExtraneousValues: true })
      }),
        catchError(this.handleError),
      )
  }

  // 全域天空气质量
  getGlobalDayAirQuality(areaDayAirQualityParams: GlobalAirQualityParams) {
    return this.http.get<GlobalAirQualityDto>(`${this.coreCofnig.baseURL}/whole-area/day-air-quality`, {
      params: areaDayAirQualityParams.toHttpParams(),
    }).pipe(map(res => {
      return plainToClass(GlobalAirQualityDto, res, { excludeExtraneousValues: true })
    }),
      catchError(this.handleError),
    )
  }

  // 全域预测天空气质量
  getGlobalForecastDayAirQuality(areaForecastDayAirQualityParams: AreaForecastDayAirQualityParams) {
    return this.http.get<AreaForecastDayAirQualityDtoLq>(`${this.coreCofnig.baseURL}/whole-area/forecast-day-air-quality`, {
      params: areaForecastDayAirQualityParams.toHttpParams(),
    }).pipe(map(res => {
      return plainToClass(AreaForecastDayAirQualityDtoLq, res, { excludeExtraneousValues: true })
    }),
      catchError(this.handleError),
    )
  }


  getAreaData() {
    return this.http.get<ResponseType<ClientBase>>(`${environment.baseURL}/data-resource-isolation/resourceList/getControlArea`).toPromise()
  }

  // 获取网格小时推断详情数据
  getHoureAirQualitySurvey(params: any) {
    return this.http.get<any>(`${environment.baseURL}/platform-alpha-statistical/grid/inference/hour-air-quality/survey`,
      { params },
    )
  }
}
