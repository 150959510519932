import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { navLength } from '@app/shared/utils/nav'
import { NavController } from '@ionic/angular'
import { filter } from 'rxjs/operators'

interface NavigateAction {
  type: 'redirect'

  target?: string
}


@Injectable({
  providedIn: 'root',
})
export class HistoryService {

  private navActions: NavigateAction[] = []

  private isLocked = false

  private backCallback: () => boolean

  constructor(
    private router: Router,
    private nav: NavController,
  ) {

  }



  // 当前页面的地址进行修改，可以避免当前页页再次出现
  replaceWithHash(hash: string) {
    const href = location.href
    const target = href.split('#')[0] + '#' + hash

    history.replaceState(null, null, target)
  }

  // 锁定后退按钮
  lockBack() {
    history.pushState(null, null, location.href)
    this.isLocked = true

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
    ).subscribe(e => {
      history.pushState(null, null, window.location.href)
    })

    window.addEventListener('popstate', () => {
      // setTimeout(() => {
      //   this.nav.pop()
      // }, 100)
      // history.go(-2)
      // history.replaceState(null, document.title, location.pathname)



      if (this.isLocked) {
        history.pushState(null, null, location.href)
      }
      setTimeout(() => {
        if (navLength(this.nav) > 1) {
          this.nav.pop()
        } else {
          if (this.router.url !== '/tabs/events') {
            this.nav.navigateRoot('/tabs/events')
          }
        }
      }, 0)
    })
  }

  // 解锁后退按钮
  unlockBack() {
    this.isLocked = false
    this.backCallback = null
  }

  // 使用回调函数锁定，返回true会自动解锁
  handleBack(callback: () => boolean) {
    this.lockBack()
    this.backCallback = callback
  }



}
