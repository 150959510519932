
declare var WeixinJSBridge: any

export function wxBrdigeReady(callback: () => void) {
  if (typeof WeixinJSBridge === 'undefined') {
    document.addEventListener('WeixinJSBridgeReady', () => {
      callback()
    }, false)
  } else {
    callback()
  }
}

export function setToolBar(show: boolean) {
  wxBrdigeReady(() => {
    if (show) {
      WeixinJSBridge.call('showToolbar')
    } else {
      WeixinJSBridge.call('hideToolbar')
    }
  })
}

export function isX5() {
  const agent = navigator.userAgent
  return /MQQBrowser\/\d+\.\d+/.test(agent)
}
