export default class Color {
  color: number[] | string
  constructor(color: number[] | string) {
    this.color = color
  }

  rgbToHex (): string {
    if (this.color.length !== 3) {
      throw new Error('rgbToHex传入数组长度有误')
    }

    if ((!this.color[0] && this.color[0] !== 0) || isNaN(Number(this.color[0]))) {
      throw new Error('rgbToHex参数r不存在或为NaN')
    }
    if ((!this.color[1] && this.color[1] !== 0) || isNaN(Number(this.color[1]))) {
      throw new Error('rgbToHex参数g不存在或为NaN')
    }
    if ((!this.color[2] && this.color[2] !== 0) || isNaN(Number(this.color[2]))) {
      throw new Error('rgbToHex参数b不存在或为NaN')
    }

    if (this.color[0] < 0 || this.color[0] > 255) {
      throw new Error('rgbToHex参数r范围有误')
    }

    if (this.color[1] < 0 || this.color[1] > 255) {
      throw new Error('rgbToHex参数g范围有误')
    }
    if (this.color[2] < 0 || this.color[2] > 255) {
      throw new Error('rgbToHex参数b范围有误')
    }
    const r: number = Number(this.color[0])
    const g: number = Number(this.color[1])
    const b: number = Number(this.color[2])
    // 判断进来的数值是否在0-255之间
    const hex = (r * 65536 + g * 256 + b).toString(16)
    return `#${new Array(Math.abs(hex.length - 7)).join('0')}${hex}`
  }

  hexToRgb (): number[] {
    if (!this.color) {
      throw new Error('hexToRgb传入参数不存在')
    }
    if (this.color.length !== 7) {
      throw new Error('hexToRgb传入参数长度有误')
    }
    const hex: string = this.color.toString()
    const rgb = []
    const len = 7
    for (let i = 1; i < len; i += 2) {
      rgb.push(parseInt(`0x${hex.slice(i, i + 2)}`, 16))
    }
    return rgb
  }
}

export function hexToRGBA(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

