import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router'
import { getSearchString } from '@app/util/event'

import { AuthService } from '../api/auth/auth.service'
import { TokenManager } from '../api/token/token.manager'
// import { AuthService } from '@app/core'

import { LoginStateService } from './login-state.service'
import { PermissionManager } from './permission.manager'

@Injectable({
  providedIn: 'root',
})

export class CanActivateService implements CanActivate {
  constructor(
    private router: Router,
    private state: LoginStateService,
    private authService: AuthService,
    private permission: PermissionManager,
    private token: TokenManager,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    // getSearchString()

     if(this.token.get() == null){

      return true
     }
     else if (this.authService.checkValid()) {

      return true

    }
    // else if (this.state.isLogin) {

    //   return this.router.parseUrl('/redirect?from=' + state.url)
    // }
    else {

      return this.router.parseUrl('/login')
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(this.token.get() == null){

      return true
     }
     else if (this.authService.checkValid()) {

      return true

    }
    // else if (this.state.isLogin) {

    //   return this.router.parseUrl('/redirect?from=' + state.url)
    // }
    else {

      return this.router.parseUrl('/login')
    }
  }


}
