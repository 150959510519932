import { Expose, Type } from 'class-transformer'
import { IndexModel } from './index-model'

export class AirBase extends IndexModel {
  @Expose({ name: 'primary_pollutants' })
  @Type(() => String)
  primaryPollutions: any

  @Expose()
  @Type(() => Number)
  grade: number
}

