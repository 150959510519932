import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  // TODO: 可以扩展成在生成环境中向服务器发送日志
  constructor() {
    // console.log('LoggerService')
  }

  log(msg: string) { console.log(msg) }
  error(msg: string) { console.error(msg) }
  warnning(msg: string) { console.warn(msg) }
}
