
import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'

export class AreaHourWeatherParams extends PageParamsPayload {
  @Expose({ name: 'area_code' })
  @Type(() => String)
  areaCode?: string[] | string

  @Expose({ name: 'publishedAtBegin' })
  @Type(() => String)
  publishedAtBegin?: string

  @Expose({ name: 'publishedAtEnd' })
  @Type(() => String)
  publishedAtEnd?: string

  constructor(params: {
    areaCode?: string[] | string,
    publishedAtBegin?: string,
    publishedAtEnd?: string
    page?: number,
    perPage?: number,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
