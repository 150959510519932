import { getCurrentAppTheme, AppTheme } from './theme'



// 43073340667cd87a6e7b879cabc3efc3 深蓝色
// 225f23c8e1180188a97fe674d4b137d8 灰色

export const MAP_KEY = 'fb8c38a7da142625f82dadb751d62a0f'

// 7a184dd8f4dcd5c5a7e20e8028803919 // 蓝色key
// fb8c38a7da142625f82dadb751d62a0f // 黑色key


export const MAP_LOCA_URL = `https://webapi.amap.com/loca?v=1.3.2&key=${MAP_KEY}`


export interface MapStyleConfig {
  style: string
  key: string
}

const darkMapStyle: MapStyleConfig = {
  style: 'amap://styles/225f23c8e1180188a97fe674d4b137d8',
  key: 'fb8c38a7da142625f82dadb751d62a0f',
}

const lightMapStyle: MapStyleConfig = {
  style: 'amap://styles/light',
  key: 'fb8c38a7da142625f82dadb751d62a0f',
}

export function getAppMapStyle() {
  const mapStyleMap = {
    [AppTheme.dark]: darkMapStyle,
    [AppTheme.light]: lightMapStyle,
  }
  return mapStyleMap[getCurrentAppTheme()]
}

