import { Expose, Type } from 'class-transformer'

import { Page } from './page'

export class Meta {
  @Expose()
  @Type(() => Page)
  pagination: Page

  @Expose({ name: 'latest_published_at' })
  @Type(() => Date)
  latestPublishedAt: Date
}
