export interface PollutionListBase {
 rows: {
    actionButtons: number
    address: string
    answerId?: any
    areaName: string
    clientId: string
    distributedTime: string
    eventType: string
    from?: any
    id: number
    isTemporarySource: number
    lat: string
    lifeCycleStatus?: any
    lon: string
    messageId?: any
    opTime: string
    orgId?: any
    pollutionName: string
    pollutionType: string
    processingType?: any
    pushNumber: string
    pushTarget?: any
    pushTime: string
    returnDesc?: any
    returnTime?: any
    score?: any
    sourceId: string
    status: number
    taskStatus: string
    tlId: number
    toId: string
    urgingStatus?: any,
 }[]
 total: number
}

export interface DistributeEventBase  {
   problemType: string|(string|null)[]|null|undefined
  address: string
  answerId?: number
  client_id?: string
  eventType?: string
  id: number |string
  lat: number
  lifeCycleStatus: string
  lon: number
  messageId: number
  orgId?: string
  isTemporarySource?: number
  pollutionName: string
  pollutionType: string
  processingType?: number
  pushNumber: string
  pushTarget?: string
  pushTime: string
  returnDesc?: string
  returnTime?: string
  score?: number
  status: number
  urgingStatus?: number
  taskStatus?: string
  actionButtons?: number
  toReport?: boolean
  tlId: number
  buttons?: string[]
  reportTime: string
  sourceId?: string
  location?: string
  searchName?: string
}



export enum DistributeEventAction {
   view,
   dispatch,
   edit,
   urge,
   affirm,
   widthdraw,
   review,
   recieve,
   feedback,
   submit,
   withdraw,
 }

 export enum DistributeEventBtnStatus {
   number1 = 1,
   number2 = 2,
   number3 = 3,
   number4 = 4,
   number5 = 5,
   number6 = 6,
   number7 = 7,
   // number8=8,
   number0 = 0,
   number9 = 9,
   number10=10,

 }


 export function EventActionName(action: DistributeEventAction) {
   const mapping = {
     [DistributeEventAction.view]: '查看',
     [DistributeEventAction.dispatch]: '派发',
     [DistributeEventAction.edit]: '编辑',
     [DistributeEventAction.urge]: '催办',
     [DistributeEventAction.affirm]: '复核无误',
     [DistributeEventAction.widthdraw]: '撤回',
     [DistributeEventAction.review]: '申请复核',
     [DistributeEventAction.feedback]: '反馈',
     [DistributeEventAction.recieve]: '接收',
     [DistributeEventAction.submit]: '确认提交',
     [DistributeEventAction.withdraw]: '取消申请',
   }
   return mapping[action]
 }


export enum EventStatus {
  wait = 'toBeReceived',
  waitCheck = 'waitCheck',
  finished = 'feedbacked',
  waitReview = 'toBeReviewed',
  redrawed = 'rejected',
  beRedrawed = 'beRejected',
  pushed = 'pushed',
  submitted = 'submitted',
  expired = 'expired',
  frozen = 'frozen',
}


 export function EventStatusName(status: EventStatus) {
  const mapping = {
    [EventStatus.wait]: '待接收',
    [EventStatus.waitCheck]: '待排查',
    [EventStatus.finished]: '已办结',
    [EventStatus.waitReview]: '待复核',
    [EventStatus.redrawed]: '已撤回',
    [EventStatus.beRedrawed]: '被撤回',
    [EventStatus.pushed]: '待派发',
    [EventStatus.submitted]: '已提交 ',
    [EventStatus.expired]: '已失效 ',
    [EventStatus.frozen]: '已冻结',


  }
  return mapping[status]
}

export interface DispatchEventBase {

  problemType: string|(string|null)[]|null|undefined
  address: string
  answerId?: number
  client_id?: string
  eventType?: string
  id: number |string
  lat: number
  lifeCycleStatus: string
  lon: number
  messageId: number
  orgId?: string
  isTemporarySource?: number
  pollutionName: string
  pollutionType: string
  processingType?: number
  pushNumber: string
  pushTarget?: string
  pushTime: string
  returnDesc?: string
  returnTime?: string
  score?: number
  status: number
  urgingStatus?: number
  taskStatus?: string
  actionButtons?: number
  toReport?: boolean
  tlId: number
  buttons?: string[]
  reportTime: string
  sourceId?: string
  location?: string
  searchName?: string
}

export enum DispatchEventBtnStatus {
  number1 = 1,
  number2 = 2,
  number3 = 3,
  number4 = 4,
  number5 = 5,
  number6 = 6,
  number7 = 7,
  // number8=8,
  number0 = 0,
  number9 = 9,
  number10=10,

}



export interface KeyAreaBase {
  items:{
    name: string
    coord: [number, number],
  }[]

}







