import { arrayString } from '@app/shared/utils'
import { Expose, Transform, Type } from 'class-transformer'
import { ParamsPayload } from './get-params'

export class PageParamsPayload extends ParamsPayload {
  @Expose()
  @Type(() => Number)
  page?: number

  @Expose()
  @Type(() => Number)
  perPage?: number

  @Expose()
  pageSize?: number

  @Expose()
  @Type(() => String)
  sortAsc: string

  @Expose()
  @Type(() => String)
  sortDesc: string

  // deprecated
  @Expose()
  @Transform((value: { [key: string]: string }) => {
    if (value) {
      const rets = []
      Object.keys(value).forEach(key => {
        rets.push([key, value[key]])
      })
      return arrayString(rets)
    }
    return undefined
  })
  order?: { [key: string]: string }
}
