
export enum EventAction {
  view = 'view',
  receive = 'receive',
  distribute = 'distribute',
  applyReview = 'applyReview',
  urge = 'urge',
  cancelApply = 'cancelApply',
  feedback = 'feedback',
  reviewConfirm = 'reviewConfirm',
  withdraw = 'withdraw',
  commitConfirm = 'commitConfirm',
  operate = 'operate',
  checkPush = 'checkPush',
  outDistribute = 'outDistribute',
  execute = 'execute'
}

export const EVENT_ACTIONS = [
  { name: '查看',key: 'view',action: 1 },
  { name: '接收',key: 'receive', hasExcecute: true, enable: true },
  { name: '派发',key: 'distribute', hasManage: true, enable: true },
  { name: '外派',key: 'outDistribute', hasManage: true, enable: true },
  { name: '核查推送',key: 'checkPush', hasManage: true, enable: true },
  { name: '强制执行',key: 'execute', hasManage: true, enable: true },

  { name: '申请复核',key: 'applyReview', action: 1, enable:true },
  { name: '催办', key: 'urge',hasManage: true ,toReport: true },
  { name: '取消申请', key: 'cancelApply', hasManage: true, enable:true },
  { name: '反馈',key: 'feedback',hasExcecute: true, enable: true },
  { name: '复核无误',key: 'reviewConfirm',hasManage: true },
  { name: '撤回',key: 'withdraw',hasManage: true },
  { name: '确认提交',key: 'commitConfirm',hasExcecute: true, toReport: true, enable: true },
  {name:'操作',key:'operate',enable: true},
]

export function EventStatusBtnName(key: string) {
  return EVENT_ACTIONS.find(ele => {
   return ele.key === key
  })?.name ?? '/'

}

export enum DispatchEventAction {
  view ,
  dispatch,
  outDispatch,
  edit,
  urge,
  affirm,
  widthdraw,
  review,
  recieve,
  feedback,
  submit,
  withdraw,
  execute
}

export enum DispatchEventActionData {
  view = 'view' ,
  dispatch = 'dispatch',
  outDispatch = 'outDispatch',
  edit = 'edit',
  urge = 'urge',
  affirm = 'affirm',
  widthdraw ='widthdraw',
  review = 'review',
  recieve = 'recieve',
  feedback = 'feedback',
  submit ='submit',
  withdraw = 'withdraw',
  execute = 'execute'
}
