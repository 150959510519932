
import { Expose, Type } from 'class-transformer'
import { WeatherBase } from '../../types'


export class AreaWeatherFeatureDto {
  @Expose()
  @Type(() => WeatherBase)
  items: WeatherBase[]
}
