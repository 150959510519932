import { Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { MobileStationAirQuality, MobileStationRealQuality } from '../model'


export class GetMobileStationQualityDto {
  @Expose()
  items: MobileStationAirQuality[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
