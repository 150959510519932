
import { direDict } from './grid-dire-dict'
import GridEdge from './grid-edge'
import GridType from './grid-type'

/* tslint:disable */

export default class GridOutline {
  areas
  map: AMap.Map
  polylines: AMap.Polyline[] = []

  strokeColor = '#3366FF'

  constructor(data: GridType[], map: AMap.Map) {
    this.map = map
    this.initPolyline(data)
  }

  initPolyline(data: any[]) {

    this.areas = this.splitGridArea(data)
    this.areas.forEach(area => {
      this.polylines.push(this.displayPath(this.getGridsBound(area), this.map))
    })
  }

  hide() {
    this.polylines.map(polyline => polyline.hide())
  }

  show() {
    this.polylines.map(polyline => polyline.show())
  }

  splitGridArea(grids: GridType[]) {
    const areas = []

    const gridDict = grids.reduce((all, grid) => {
      all[`${grid.row}-${grid.column}`] = grid

      return all
    }, {})

    while (Object.keys(gridDict).length) {
      const key = Object.keys(gridDict)[0]
      const currentGrid = gridDict[key]
      const area = [currentGrid]
      delete gridDict[key]
      this.getNearGrid(gridDict, currentGrid, area)
      areas.push(area)
    }
    return areas
  }

  getNearGrid(gridDict, grid: GridType, area) {
    if (gridDict[`${grid.row + 1}-${grid.column}`]) {
      const selectGrid =
        gridDict[`${grid.row + 1}-${grid.column}`]
      area.push(selectGrid)
      delete gridDict[`${grid.row + 1}-${grid.column}`]
      this.getNearGrid(gridDict, selectGrid, area)
    }

    if (gridDict[`${grid.row - 1}-${grid.column}`]) {
      const selectGrid =
        gridDict[`${grid.row - 1}-${grid.column}`]
      area.push(selectGrid)
      delete gridDict[`${grid.row - 1}-${grid.column}`]
      this.getNearGrid(gridDict, selectGrid, area)
    }

    if (gridDict[`${grid.row}-${grid.column + 1}`]) {
      const selectGrid =
        gridDict[`${grid.row}-${grid.column + 1}`]
      area.push(selectGrid)
      delete gridDict[`${grid.row}-${grid.column + 1}`]
      this.getNearGrid(gridDict, selectGrid, area)
    }

    if (gridDict[`${grid.row}-${grid.column - 1}`]) {
      const selectGrid =
        gridDict[`${grid.row}-${grid.column - 1}`]
      area.push(selectGrid)
      delete gridDict[`${grid.row}-${grid.column - 1}`]
      this.getNearGrid(gridDict, selectGrid, area)
    }
  }

  getGridsBound(grids) {
    // 网格字典
    const gridDict = grids.reduce((all, grid) => {
      all[`${grid.row}-${grid.column}`] = grid

      return all
    }, {})

    // 筛选边界网格
    const boundGrids = grids.filter(grid => {
      let mask = 0b0000

      if (gridDict[`${grid.row + 1}-${grid.column}`]) {
        mask |= direDict.top // 上
      }

      if (gridDict[`${grid.row}-${grid.column + 1}`]) {
        mask |= direDict.right // 右
      }

      if (gridDict[`${grid.row}-${grid.column - 1}`]) {
        mask |= direDict.left // 左
      }

      if (gridDict[`${grid.row - 1}-${grid.column}`]) {
        mask |= direDict.bottom // 下
      }

      if (mask === direDict.full) {
        return false
      } else {
        grid.boundMask = mask
        grid.useEdge = direDict.full - mask
        return true
      }
    })

    // 获取有效网格边
    let gridEdges = []
    boundGrids.forEach((grid: GridType) => {
      if (grid.useEdge & direDict.top) {
        gridEdges.push(new GridEdge(grid, direDict.top))
      }

      if (grid.useEdge & direDict.bottom) {
        gridEdges.push(new GridEdge(grid, direDict.bottom))
      }

      if (grid.useEdge & direDict.left) {
        gridEdges.push(new GridEdge(grid, direDict.left))
      }

      if (grid.useEdge & direDict.right) {
        gridEdges.push(new GridEdge(grid, direDict.right))
      }
    })

    // 连接每一条边
    let currentEdge = gridEdges[0]

    const boundPoints = []
    const counter = 0

    while (gridEdges.length) {
      // 匹配上一条边的结束点与下一条边的开始点
      const nextGridEdge = gridEdges.find(
        gridEdge =>
          gridEdge.start[0] === currentEdge.end[0] &&
          gridEdge.start[1] === currentEdge.end[1],
      )

      if (!nextGridEdge) { break }
      boundPoints.push([...nextGridEdge.start])
      currentEdge = nextGridEdge
      gridEdges = gridEdges.filter(gridEdge => gridEdge !== currentEdge)
    }

    // 添加最后一个点
    boundPoints.push([...currentEdge.end])

    return boundPoints
  }

  displayPath(path: [number, number][], map: AMap.Map): AMap.Polyline {
    return new AMap.Polyline({
      path,
      map,
      borderWeight: 1,
      strokeColor: this.strokeColor,
      strokeOpacity: .8,
      strokeWeight: 1,
      // 折线样式还支持 'dashed'
      strokeStyle: "solid",
      // strokeStyle是dashed时有效
      strokeDasharray: [10, 5],
    })
  }

  set selected(select: boolean) {
    if (select) {
      this.polylines.forEach(p => {
        p.setOptions({
          strokeColor: 'red',
          strokeWeight: 2,
        })
        p.setzIndex(1000)
      })
    } else {
      this.polylines.forEach(p => {
        p.setOptions({
          strokeColor: '#3366FF',
          strokeWeight: 1,
        })
        p.setzIndex(50)
      })
    }
  }

  destroy() {
    this.polylines.map(polyline => polyline && this.map.remove([polyline]))
    this.polylines = []
  }

}
