import { Expose, Type } from 'class-transformer'

export class LoginWxDto {
  @Expose()
  @Type(() => Boolean)
  success: boolean

  @Expose()
  @Type(() => String)
  msg: string

  @Expose()
  @Type(() => Number)
  code: number

  @Expose()
  @Type(() => String)
  data: string
}