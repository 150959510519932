import { arrayString } from '@app/shared/utils'

import { GridTag } from '@app/core/services/types'
import { ParamsPayload } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'

// FIXME: magic https://github.com/ng-packagr/ng-packagr/issues/696#issuecomment-373487183
// @dynamic
export class GridListTypePara extends ParamsPayload {

  @Expose()
  @Type(() => String)
  'row'?: string

  @Expose({ name: 'area_ids:contained' })
  @Transform(value => value && arrayString(value))
  'areaIdsContained'?: string[]

  @Expose({ name: 'tags:contained' })
  @Transform(value => value && arrayString(value))
  'tagsContained'?: string[]

  @Expose()
  @Type(() => String)
  'column'?: string

  @Expose()
  @Type(() => Number)
  'id'?: number

  @Expose()
  @Type(() => String)
  'remark'?: string

  @Expose()
  @Type(() => String)
  'operator'?: string

  @Expose()
  @Type(() => Number)
  'page'?: number

  @Expose({ name: 'per_page' })
  @Type(() => Number)
  'perPage'?: number

  @Expose({ name: 'center_coord.lat:between' })
  @Transform(value => value && arrayString(value))
  'centerCoordLatBetween'?: [string, string]

  @Expose({ name: 'row:between' })
  @Transform(value => value && arrayString(value))
  'rowBetween'?: [string, string]

  @Expose({ name: 'column:between' })
  @Transform(value => value && arrayString(value))
  'columnBetween'?: [string, string]

  @Expose({ name: 'center_coord.lng:between' })
  @Transform(value => value && arrayString(value))
  'centerCoordLngBetween'?: [string, string]

  @Expose({ name: 'location:like' })
  @Type(() => String)
  'locationLike'?: string

  @Expose({ name: 'remark:like' })
  @Type(() => String)
  'remarkLike'?: string

  @Expose({ name: 'operator:like' })
  @Type(() => String)
  'operatorLike'?: string

  @Expose({ name: 'updated_at:between' })
  @Transform(value => value && arrayString(value))
  'updatedAtBetween'?: [Date, Date]

  @Expose({ name: 'tags:contains' })
  @Transform((req) => req && arrayString(req), { toPlainOnly: true })
  'tagsContains'?: GridTag[]

  @Expose()
  size: number

@Expose({ name: 'is_owned' })
  @Type(() => String)
  'isOwned'?: string

  @Expose({ name: 'grid_code' })
  @Type(() => String)
  'gridCode'?: string[]

  @Expose({ name: 'sort_asc' })
  @Type(() => String)
  sortAsc?: string

  @Expose({ name: 'sort_desc' })
  @Type(() => String)
  sortDesc?: string

  constructor(params: {
    'row'?: string
    'areaIdsContained'?: string
    'tagsContained'?: string
    'column'?: string
    'id'?: number
    'remark'?: string
    'operator'?: string
    'page'?: number
    'perPage'?: number
    'centerCoordLatBetween'?: [string, string]
    'rowBetween'?: [string, string]
    'columnBetween'?: [string, string]
    'centerCoordLngBetween'?: [string, string]
    'locationLike'?: string
    'remarkLike'?: string
    'operatorLike'?: string
    'updatedAtBetween'?: [Date, Date],
    tagsContains?: GridTag[],
    size?: number,
  }) {
    super()
    this.initParams(params)
  }
}
