import { Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { GridDataBase, LQGridDataBase } from '../model'

// @dynamic
export class LQGridListBaseDto {

  @Expose()
  @Type(() => LQGridDataBase)
  items: LQGridDataBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta

}
