import { plainToClass, Expose, Transform, Type } from 'class-transformer'

export class IndexModel {

  @Expose()
  @Transform(value => (value || value === 0) ? Math.ceil(value) : null)
  aqi: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(0)) : null)
  o3: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(1)) : null)
  co: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(0)) : null)
  no2: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(0)) : null)
  so2: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(0)) : null)
  'pm2_5': number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(0)) : null)
  pm10: number

  @Expose()
  @Transform(value => (value || value === 0) && Math.ceil(value))
  'o3_iaqi'?: number

  @Expose()
  @Transform(value => (value || value === 0) && Math.ceil(value))
  'co_iaqi'?: number

  @Expose()
  @Transform(value => (value || value === 0) && Math.ceil(value))
  'no2_iaqi'?: number

  @Expose()
  @Transform(value => (value || value === 0) && Math.ceil(value))
  'so2_iaqi'?: number

  @Expose()
  @Transform(value => (value || value === 0) && Math.ceil(value))
  'pm2_5_iaqi': number

  @Expose()
  @Transform(value => (value || value === 0) && Math.ceil(value))
  'pm10_iaqi'?: number

  @Expose({ name: 'primary_pollutants' })
  @Type(() => String)
  primaryPollutions?: string[]

  @Expose({ name: 'max_aqi' })
  @Type(() => Number)
  maxAqi?: number

  @Expose({ name: 'min_aqi' })
  @Type(() => Number)
  minAqi?: number



  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(1)) : null)
  humidity: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(2)) : null)
  pressure: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(2)) : null)
  temp: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(2)) : null)
  tvoc: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value.toFixed(2)) : null)
  wind_speed: number

  @Expose()
  @Transform(value => (value || value === 0) ? Number(value) : null)
  wind_direction?: number



  static fromData(data: {}) {
    return plainToClass(IndexModel, data, { excludeExtraneousValues: true })
  }
}

