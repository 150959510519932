import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'


export class GlobalAirQualityParams extends PageParamsPayload {
  @Expose()
  @Type(() => String)
  source: string[] | string

  @Expose({ name: 'publishedAtBegin' })
  @Type(() => String)
  publishedStart: string

  @Expose({ name: 'publishedAtEnd' })
  @Type(() => String)
  publishedEnd: string

  constructor(params?: {
    source?: string[] | string,
    publishedStart?: string,
    publishedEnd?: string,
    sortAsc?: string,
    sortDesc?: string,
    areaType?: string
    page?: number,
    pageSize?: number,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
