

export class ProgressIndicator {

  ctx: CanvasRenderingContext2D
  size: number

  constructor(id: string | HTMLCanvasElement, opts?: {
    size?: number,
  }) {
    let el: HTMLCanvasElement
    if (typeof id === 'string') {
      el = document.getElementById(id) as HTMLCanvasElement
    } else if (id instanceof HTMLCanvasElement) {
      el = id
    }
    if (opts && opts.size) {
      el.width = opts.size
      el.height = opts.size
      this.size = opts.size
    } else {
      this.size = el.width
    }

    el.style.width = this.size + 'px'
    el.style.height = this.size + 'px'
    this.ctx = el.getContext('2d')
    el.width = Math.floor(this.size * window.devicePixelRatio)
    el.height = Math.floor(this.size * window.devicePixelRatio)
    // this.prepare()
  }

  update(info: { value: number, color?: string }) {
    const ctx = this.ctx
    const size = this.size
    const color = info.color || 'red'
    ctx.save()
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    ctx.clearRect(0, 0, this.size, this.size)

    ctx.beginPath()
    ctx.strokeStyle = 'rgba(117,117,117,0.30)'
    ctx.lineWidth = 2
    ctx.arc(size / 2, size / 2, size / 2 - 3, 0, 2 * Math.PI)
    ctx.stroke()

    ctx.beginPath()
    ctx.strokeStyle = color
    ctx.lineWidth = 3
    ctx.arc(
      size / 2,
      size / 2,
      size / 2 - 3,
      - 0.5 * Math.PI,
      (info.value * 2 - 0.5) * Math.PI,
    )
    ctx.stroke()
    ctx.restore()
  }
}
