import { Injectable } from '@angular/core'
import { SAVE_USERNAME, TOEKN_NAME } from '@app/constants'
import { BehaviorSubject } from 'rxjs'
import { TokenManager } from '../api'

import { StorageService } from '../storage'
import { UserBase } from '../user'

@Injectable({
  providedIn: 'root',
})
export class LoginStateService {

  constructor(
    private store: StorageService,
    private tokenManager: TokenManager,
  ) {
    // this.token = this.store.get(TOEKN_NAME)`
    this.userId = Number(this.store.get('userId'))
    this.userName = this.store.get(SAVE_USERNAME)
  }

  // private token: string
  private userId: number
  private userName: string

  #user: any

  loginStateChange$ = new BehaviorSubject(false)
  userIdChange$ = new BehaviorSubject(0)

  get isLogin() {
    return this.tokenManager.get() != null
  }

  // setToken(t: string) {
  //   this.token = t
  //   console.log(t, '---')
  //   this.store.set(TOEKN_NAME, t)
  //   if (t) {
  //     this.loginStateChange$.next(true)
  //   } else {
  //     this.loginStateChange$.next(false)
  //   }
  // }

  getToken() {
    
    return this.tokenManager.get()
  }

  getUserId() {
    return this.userId
  }

  setUserId(id: number) {
    this.userId = id
    this.store.set('userId', typeof id === 'number' ? id.toFixed() : null)
    this.userIdChange$.next(this.userId)
  }

  getUserName() {
    return this.userName
  }

  setUserName(name: string) {
    this.userName = name
    this.store.set(SAVE_USERNAME, name)
  }

  get user() {
    if (!this.#user) {
      try {
        this.#user = JSON.parse(sessionStorage.getItem('user')) || null
      } catch (e) {

      }
    }
    return this.#user
  }

  set user(u: UserBase) {
    this.#user = u
    sessionStorage.setItem('user', JSON.stringify(u))
  }

  clearLoginState() {
    this.setUserName(null)
    this.tokenManager.set(null)
    this.setUserId(null)
  }

}
