import { Expose, Type } from 'class-transformer'

import { PageParamsPayload } from '@app/core/services/types'

export class AreaHourAirQualityParamsLq extends PageParamsPayload {
  @Expose({ name: 'areaCode' })
  @Type(() => String)
  areaCode: string[] | string

  @Expose()
  @Type(() => String)
  source: string[] | string

  @Expose({ name: 'publishedAtBegin' })
  @Type(() => String)
  publishedStart: string

  @Expose({ name: 'publishedAtEnd' })
  @Type(() => String)
  publishedEnd: string

  @Expose({ name: 'sortAsc' })
  @Type(() => String)
  sortAsc: string

  @Expose({ name: 'sortDesc' })
  @Type(() => String)
  sortDesc: string

  @Expose({ name: 'areaType' })
  @Type(() => String)
  areaType: string

  @Expose({ name: 'areaId' })
  areaId: number

  @Expose({ name: 'stationType' })
  stationType: string[] | string

  constructor(params?: {
    areaCode?: string[] | string,
    source?: string[] | string,
    publishedStart?: string,
    publishedEnd?: string,
    sortAsc?: string,
    sortDesc?: string,
    areaType?: string
    page: number,
    pageSize: number,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
