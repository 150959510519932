import { Expose, Type } from 'class-transformer'

import { ItemList } from '@app/core/services/types'
import { Meta } from '../../types/model'
import { GridDayAirQualitiesBase } from '../model'

export class GridDayAirQualitiesDto implements ItemList<GridDayAirQualitiesBase> {
  @Expose()
  @Type(() => GridDayAirQualitiesBase)
  items: GridDayAirQualitiesBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
