import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'


export class AreaGridParams extends PageParamsPayload {


  @Expose()
  @Type(() => String)
  areaCode?: string

  @Expose()
  @Type(() => String)
  areaType?: string

  @Expose()
  @Type(() => String)
  areaId?: string

  @Expose()
  @Type(() => String)
  gridCode?: string

  @Expose()
  @Type(() => String)
  gridRow?: string

  @Expose()
  @Type(() => String)
  gridRowBegin?: string

  @Expose()
  @Type(() => String)
  gridRowEnd?: string

  @Expose()
  @Type(() => String)
  gridColumn?: string

  @Expose()
  @Type(() => String)
  gridColumnBegin?: string

  @Expose()
  @Type(() => String)
  gridColumnEnd?: string

  @Expose()
  @Type(() => String)
  isOwned?: boolean


  constructor(params?: {
    areaCode?: string
    areaType?: string
    areaId?: string
    gridCode?: string,
    gridRow?: string,
    gridColumn?: string,
    gridRowBegin?: string,
    gridRowEnd?: string,
    gridColumnBegin?: string,
    gridColumnEnd?: string,
    isOwned?: boolean,
    pageSize?: number,
    page?: number,
    currentPage?: number,
    sortAsc?: string,
    sortDesc?: string,
  }) {
    super()
    this.initParams(params)
  }
}
