import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

// if (environment.production) {
//   Sentry.init({
//     dsn: 'https://2efcdde9c1c44b14a86f7efe8a3a8080@o1056860.ingest.sentry.io/6043280',
//     integrations: [
//       new Integrations.BrowserTracing({
//         // tracingOrigins: ["localhost", "http://t-pl-soul.glzt.com.cn"],
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,

//   })
//   enableProdMode()
// }

Sentry.init({
  dsn: 'https://c020fe8924afdfd606cda0e873eddf93@sentry.glzt.com.cn/14',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment.sentry,
})

enableProdMode()

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))
