import { PageParamsPayload, ParamsPayload } from '@app/core/services/types'
import { Expose, Transform } from 'class-transformer'
import { format } from 'date-fns'


export class GridQueryParams extends PageParamsPayload {

  @Expose()
  @Transform((val: Date) => val && val instanceof Date ? format(val, 'yyyy-MM-dd HH:mm:ss') : val, { toPlainOnly: true })
  publishedAtBegin: string | Date

  @Expose()
  @Transform((val: Date) => val && val instanceof Date ? format(val, 'yyyy-MM-dd HH:mm:ss') : val, { toPlainOnly: true })
  publishedAtEnd: string | Date

  @Expose()
  // @Transform((val: Date) => val && val.toISOString(), { toPlainOnly: true })
  publishedAt: string

  @Expose()
  gridId: number | number[]

  @Expose()
  gridCode: string

  @Expose()
  isOwned: string

  constructor(params?: {
    gridId?: number | number[],
    publishedAtBegin?: Date,
    publishedAtEnd?: Date,
    pageSize?: number,
    page?: number,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
