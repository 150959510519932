import { Expose, Type } from 'class-transformer'

export class GetAreaListItem {
  @Expose()
  @Type(() => Number)
  'id'?: number

  @Expose()
  @Type(() => String)
  'name'?: string

  @Expose({ name: 'area_type' })
  @Type(() => String)
  'areaType'?: string

  @Expose({ name: 'created_at' })
  @Type(() => String)
  'createdAt'?: string

  @Expose({ name: 'updated_at' })
  @Type(() => String)
  'updatedAt'?: string

  @Expose({ name: 'deleted_at' })
  @Type(() => String)
  'deletedAt'?: string

  @Expose({ name: 'area_code' })
  @Type(() => String)
  'areaCode'?: string

  @Expose({ name: 'grid_num' })
  @Type(() => String)
  'gridNum'?: string

  @Expose()
  @Type(() => String)
  'location'?: string

}
