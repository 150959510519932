import 'reflect-metadata';
import '../polyfills';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx'
import { Uid } from '@ionic-native/uid/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MAP_KEY } from './constants';
import { ResponseSuccessInterceptor } from './core/interceptors';
import { AuthInterceptor } from './core/services/api/auth.interceptor';

import { ImagePicker } from '@ionic-native/image-picker/ngx'

import { Device } from '@ionic-native/device/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';


// import { Camera } from '@awesome-cordova-plugins/camera/ngx';
const errorHandleProviders = environment.production
  ? [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
          logErrors: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ]
  : [];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [

    BrowserModule,
    IonicModule.forRoot({
      // mode: 'ios',
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
  ],
  providers: [
    Device,
    UniqueDeviceID,
    Uid,
    Geolocation,
    PhotoLibrary,
    File,
    ImagePicker,
    FileChooser,
    FileTransfer,
    FileOpener,
    FileTransferObject,
  // Camera,
    AndroidPermissions,
    AppAvailability,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseSuccessInterceptor,
      multi: true,
    },
    // { provide: NZ_I18N, useValue: zh_CN },
    ...errorHandleProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
