import { Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { StationWeatherBase } from '../model'


export class StationWeatherDto {
  @Expose()
  @Type(() => Meta)
  meta: Meta

  @Expose()
  @Type(() => StationWeatherBase)
  items: StationWeatherBase[]
}
