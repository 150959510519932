import { Coord, IndexModel } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'
import { parseISO } from 'date-fns'


export class StationForecastAirQualityBase {

  @Expose({ name: 'stationId' })
  @Type(() => Number)
  stationId: number

  @Expose()
  @Type(() => IndexModel)
  data: IndexModel

  @Expose({ name: 'futureHour' })
  @Type(() => String)
  futureHour: string

  @Expose({ name: 'forecastAt' })
  @Transform(val => val && parseISO(val), { toClassOnly: true })
  forecastAt: Date

  @Expose({ name: 'publishedAt' })
  @Transform(val => val && parseISO(val), { toClassOnly: true })
  publishedAt: Date

  @Expose({ name: 'stationName' })
  @Type(() => String)
  stationName: string

  @Expose({ name: 'stationCode' })
  @Type(() => String)
  stationCode: string

  @Expose({ name: 'stationLevel' })
  @Type(() => String)
  stationLevel: string

  @Expose()
  @Type(() => Coord)
  coord: Coord

  @Expose()
  @Type(() => String)
  location: string

  @Expose({ name: 'stationType' })
  @Type(() => String)
  stationType: string
}
