import { Expose, Type } from 'class-transformer'

import { ItemList, Meta } from '@app/core/services/types'
import { GetFixedStationHourAirQualityBase } from '../model'

export class GetFixedStationHourAirQualityDto implements ItemList<GetFixedStationHourAirQualityBase> {
  @Expose()
  @Type(() => GetFixedStationHourAirQualityBase)
  items: GetFixedStationHourAirQualityBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
