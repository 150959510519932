import { Coord } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'

// @dynamic
export class LQGridDataBase {
  @Expose({ name: 'id' })
  @Type(() => Number)
  id0: number

  @Expose({ name: 'gridId' })
  id1: number

  get id() {
    return this.id0 || this.id1
  }

  @Expose()
  @Type(() => String)
  size: string

  @Expose({ name: 'gridRow' })
  @Type(() => Number)
  row: number

  @Expose({ name: 'gridColumn' })
  @Type(() => Number)
  column: number

  @Expose()
  @Type(() => Coord)
  centerCoord: Coord

  @Expose()
  @Type(() => String)
  tags: string[]

  @Expose()
  @Type(() => String)
  location: string

  @Expose()
  @Type(() => String)
  operator: string

  @Expose()
  @Type(() => String)
  remark: string

  @Expose()
  @Type(() => String)
  gridCode: string

  @Expose()
  @Type(() => Date)
  updatedAt: Date

  @Expose()
  @Type(() => Date)
  createdAt: Date

  @Expose()
  @Type(() => Coord)
  bottomLeftCoord: Coord

  @Expose()
  @Type(() => Coord)
  topRightCoord: Coord

  @Expose()
  isOwned: boolean

  @Expose()
  gridScale: number

  /** areas */

  @Expose()
  areaId: number

  @Expose()
  areaName: string

  @Expose()
  areaCode: string

  @Expose()
  areaType: string

  @Expose()
  area: { area_id: number, area_name: string, area_type: string }[]
}
