import { Expose, Type } from 'class-transformer'

export class TrafficsBase {
  /**
   * 整体畅通
   */
   @Expose({ name: 'smooth_overall' })
   @Type(() => Number)
   'smoothOverall'?: number

   /**
    * 轻度拥堵
    */
   @Expose({ name: 'mild_congest' })
   @Type(() => Number)
   'mildCongest'?: number

   /**
    * 中度拥堵
    */
   @Expose({ name: 'moderate_congest' })
   @Type(() => Number)
   'moderateCongest'?: number

   /**
    * 整体拥堵
    */
   @Expose({ name: 'overall_congest' })
   @Type(() => Number)
   'overallCongest'?: number

   /**
    * 严重拥堵
    */
   @Expose({ name: 'severe_congest' })
   @Type(() => Number)
   'severeCongest'?: number

   /**
    * 拥堵指数
    */
   @Expose({ name: 'congest_index' })
   @Type(() => Number)
   'congestIndex'?: number

   @Expose({ name: 'published_at' })
   @Type(() => Date)
   publishedAt?: Date

   /**
    * 缓行路段
    */
   @Expose({ name: 'slow_road' })
   @Type(() => Number)
   'slowRoad'?: number

   /**
    * 畅通路段
    */
   @Expose({ name: 'smooth_road' })
   @Type(() => Number)
   'smoothRoad'?: number

   /**
    * 拥堵路段
    */
   @Expose({ name: 'congest_road' })
   @Type(() => Number)
   'congestRoad'?: number

   /**
    * 未知路段
    */
   @Expose({ name: 'unknown_road' })
   @Type(() => Number)
   'unknownRoad'?: number

   /**
    * 路况状态
    */
   @Expose({ name: 'traffic_status' })
   @Type(() => Number)
   'trafficStatus'?: number | string
}
