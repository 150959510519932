

export const DATE_FORMAT = {
  FULL: 'YYYY-MM-DD HH:mm:ss',
  LOWERFULL: 'yyyy-MM-dd HH:mm:ss',
  TIME: 'HH:mm:ss',
  DATE: 'YYYY-MM-DD',
  DATE_MINUTE: 'YYYY-MM-DD HH:mm',
  DATE_HOUR: 'YYYY-MM-DD HH',
  DAY: 'MM-DD',
  DAY_HOUR: 'MM-DD HH',
  DAY_MINUTE: 'MM-DD HH:mm',
  HOUR: 'HH',
}
