import { Meta } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'
import { StationForecastAirQualityBase } from '../model'


export class StationForecastDto {
  @Expose()
  @Type(() => StationForecastAirQualityBase)
  items: StationForecastAirQualityBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
