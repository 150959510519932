import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  public localStorage: any
  constructor() {
    if (!localStorage) {
      throw new Error('Current browser does not support Local Storage')
    }
    this.localStorage = localStorage
  }

  /**
   * key: 键名 value：修改的值
   */
  set(key: string, value: string): void {
    this.localStorage[key] = value ? JSON.stringify(value) : value
  }
  /**
   * key: 键名 获取值
   */
  get(key: string): any {
    const value = this.localStorage.getItem(key)
    if (!value) { return }
    let val: any
    try {
      val = JSON.parse(value)
    } catch {
      val = value
    }
    return val
  }
  /**
   * key: 键名 单个移除
   */
  remove(key: string): void {
    this.localStorage.removeItem(key)
  }
  /**
   * 全部移除
   */
  removeAll(): void {
    this.localStorage.clear()
  }

}
