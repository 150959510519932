
import { Coord, IndexModel } from '@app/core/services/types'
import { Expose, Transform, Type } from 'class-transformer'
import { parseISO } from 'date-fns'

export class GetFixedStationHourAirQualityBase {
  @Expose()
  @Type(() => Number)
  id: number

  @Expose()
  @Type(() => Number)
  gridId: number

  @Expose()
  @Transform((req) => {
    // tslint:disable-next-line: max-line-length
    const idx = ['nation_ctrl_station', 'province_ctrl_station','city_ctrl_station', 'district_ctrl_station', 'alphamaps_micro_station', 'alphamaps_station_building'].indexOf(req)
    return (['NCS', 'CCS','SK', 'MAPS', 'alphamaps_micro_station', 'alphamaps_station_building'][idx] || '')
  })
  source: string

  // @Expose()
  // @Type(() => String)
  // stationType: string

  @Expose()
  @Type(() => String)
  stationLevel: string

  @Expose()
  @Type(() => String)
  stationName: string

  @Expose()
  @Type(() => String)
  stationCode: string

  @Expose()
  @Type(() => IndexModel)
  data: IndexModel

  @Expose()
  @Type(() => Number)
  stationId: number

  @Expose()
  @Transform(val => val && parseISO(val), { toClassOnly: true })
  publishedAt: Date

  @Expose()
  @Type(() => Coord)
  coord: Coord
}
