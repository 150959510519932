import { Expose, Type } from 'class-transformer'

export class AreaHourWeatherBaseData {
  @Expose()
  @Type(() => Number)
  'temp'?: number

  @Expose()
  @Type(() => Number)
  'humidity'?: number

  @Expose()
  @Type(() => Number)
  'pressure'?: number

  @Expose()
  @Type(() => Number)
  'rainfall'?: number

  @Expose({ name: 'wind_speed' })
  @Type(() => Number)
  'windSpeed'?: number

  @Expose({ name: 'wind_degrees' })
  @Type(() => Number)
  windDegrees?: number

  @Expose({ name: 'solar_radiation' })
  @Type(() => Number)
  'solarRadiation'?: number
}
