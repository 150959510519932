import { Expose, Type } from 'class-transformer'

import { ItemList, Meta } from '@app/core/services/types'
import { AreaHourAirQualityBaseLq } from '../model'


export class AreaHourAirQualityDtoLq implements ItemList<AreaHourAirQualityBaseLq> {
  @Expose()
  @Type(() => AreaHourAirQualityBaseLq)
  items: AreaHourAirQualityBaseLq[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
