import { Injectable } from '@angular/core'
import { AuthService } from '@app/core/services/api/auth'


@Injectable({
  providedIn: 'root',
})
export class PermissionManager {

  constructor(
    private auth: AuthService,
  ) {

  }


  _permissions: { code: string, name: string }[]

  setPermission(permission: { code: string, name: string }[]) {
    this._permissions = permission
  }

  getPermissions(): Promise<{ code: string, name: string }[]> {
    if (this._permissions) {
      return Promise.resolve(this._permissions)
    } else {
      return new Promise((resolve, reject) => {
        this.auth.getPermissionCode().subscribe(rts => {
          if (rts?.success) {
            this._permissions = rts.data.concat()
            resolve(this._permissions)
          } else {
            this._permissions = []
            reject(new Error(rts.msg))
          }
        }, err => {
          reject(new Error('获取权限失败'))
        })
      })
    }
  }

  valid() {
    return this._permissions != null
  }
}
