/**
 * 站点类型 （fixed :固定站点 mobile：移动微站）
 */
export type StationType = 'fixed' | 'mobile'

export enum AirStationType {
  ncs = 'nation_ctrl_station',
  pcs = 'province_ctrl_station',
  dcs = 'district_ctrl_station',
  afms = 'alphamaps_micro_station', // 微站
  amms = 'alphamaps_mobile_micro_station',
  ast = 'alphamaps_sub_station',  // 子站
  sk = 'city_ctrl_station',
  tvoc = 'tvoc_station'
}

export enum AirStationCarType {
  bus = 'bus',
  own = 'self_owned_vehicle',
  taxi = 'taxi',
}

export const AirStationNameMap = {
  [AirStationType.ncs]: '国控站点',
  [AirStationType.pcs]: '省控站点',
  [AirStationType.sk]: '市控站点',
  [AirStationType.dcs]: '区控站点',
  [AirStationType.afms]: '微站',
  [AirStationType.amms]: '移动微站',
  [AirStationType.ast]: '子站',
  [AirStationType.tvoc]: 'TVOC站点',
  [AirStationCarType.bus]: '自建移动站',
  [AirStationCarType.own]: '自建移动站',
  [AirStationCarType.taxi]: '自建移动站',
}

export const AirStationCarNameMap = {
  [AirStationCarType.bus]: '公交车',
  [AirStationCarType.taxi]: '出租车',
  [AirStationCarType.own]: '自购车',
}
