

export enum PollutionSourceType {
  business = 1,
  construction_site,
  traffic,
  unknown,
}

export const PollutionSourceTypes = [
  { key: PollutionSourceType.business, title: '企业' },
  { key: PollutionSourceType.construction_site, title: '工地' },
  { key: PollutionSourceType.traffic, title: '交通' },
  { key: PollutionSourceType.unknown, title: '未知' },
]


// export const POLLUTION_TYPES = localStorage.pollutionTypesData?JSON.parse(localStorage.pollutionTypesData):[]
// export const POLLUTION_TYPES=
//  [
//   { key: 'constructionSitePollution', label: "工地扬尘污染" },
//   { key: 'factoryEmission', label: "企业废气排放" },
//   { key: 'trafficFumes', label: "交通尾气排放" },
//   { key: 'trafficDust', label: "交通扬尘污染" },
//   { key: 'other', label: "其他" }
// ]

export const OPTION_TYPES = localStorage.optionData?JSON.parse(localStorage.optionData):[]

// 污染源类型
export const POLLUTE_TYPE_OPTHION = [
  {
    key : 'construction_site' ,
    label : '工地',
  },
  {
    key : 'factory' ,
    label : '工厂',
  },
  {
    key : 'other' ,
    label : '加油站&其他',
  },
]
// 审核状态
export const CHECK_STATUS = [
  {
    key: 0,
    label: '未通过审核',
  },
  {
    key: 1,
    label: '审核中',
  },
  {
    key: 2,
    label: '审核通过',
  },
  {
    key: 3,
    label: '未推送',
  },
]
export const POLLUTION_PROJECT_TYPES = [
  { label: '市政', value: 1 },
  { label: '轨道交通', value: 2 },
  { label: '房建', value: 3 },
  { label: '深基坑', value: 4 },
  { label: '装饰装修', value: 5 },
  { label: '砂浆站', value: 6 },
  { label: '混凝土', value: 7 },
  { label: '砂浆混凝土', value: 8 },
]

export const POLLUTION_SOURCE_SCALES = [
  { label: '微型', value: 'miniature' },
  { label: '小型', value: 'small' },
  { label: '中型', value: 'medium' },
  { label: '大型', value: 'large' },
  { label: '未知', value: 'unknown' },
]

export const POLLUTION_PROJECT_STAGES = [
  { label: '基地开挖', value: 1 },
  { label: '主体修建', value: 2 },
  { label: '主体完工', value: 3 },
  { label: '已竣工', value: 4 },
]

export const POLLUTION_ENTERPRISE_LISCENCES = [
  { label: '正常', value: 'normal' },
  { label: '已到期', value: 'expire' },
  { label: '未处理', value: 'notHandle' },
]

export const POLLUTION_ENTERPRISE_STATUS = [
  { label: '已停产', value: 'stopProduction' },
  { label: '正常', value: 'normal' },
]


