import { PageParamsPayload, StationSourceType, StationType } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'


export class GetFixedStationParams extends PageParamsPayload {

  @Expose()
  @Type(() => String)
  code: string

  @Expose()
  @Type(() => String)
  stationType?: string | string[]

  @Expose()
  @Type(() => String)
  stationName?: string

  @Expose()
  @Type(() => String)
  stationCode?: string | string[]

  @Expose()
  @Type(() => String)
  stationLevel?: string

  @Expose()
  districtCode: string

  @Expose()
  cityCode: string

  @Expose()
  @Type(() => String)
  search?: string

  @Expose()
  id: number

  @Expose()
  status: number

  @Expose()
  isOwned: number


  constructor(params?: {
    type?: StationType,
    stationType?: string | string[],
    stationName?: string,
    stationCode?: string | string[],
    stationLevel?: string,
    source?: StationSourceType[],
    isOwned?: boolean,
    code?: string,
    pageSize?: number,
    page?: number,
    sortAsc?: number
    sortDesc?: number,
    search?: string,
    id?: number,
    districtCode?: string,
    cityCode?: string,
    userId?: number,
    stationTypes?: string[]
  }) {
    super()
    if (params) { this.initParams(params) }
  }
}
