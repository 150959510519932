export const URL_SESSION = 'url'
export const TOEKN_NAME = 'AM_WORK_MOBILE_TOKEN'
export const SAVE_USERNAME = 'username'
// export const API_MAP_KEY = 'cdb11bf32796e14cea68bd39707024f9' // 地图key
export const API_MAP_KEY = '7a184dd8f4dcd5c5a7e20e8028803919' // 地图key
export const MAP_STYLE = 'amap://styles/74f52c0eef2df2b8daa4f49ceb81d9cb' // 地图样式
export const NODATA_COLOR = '#808080' // 没有数据背景颜色
export const FORCAST_RANGE_NUM = 15 // 预测计算区间值
export const FORCAST_RANGE_PER = 0.25 // 预测计算区间值
export const BASE_URL_KEY_TOKEN = 'aaaaa'
export const MAP_VERSION = '1.4.15'
export const CENTER = [104.297475, 30.584747]

