

export interface IndexTemplate {
  iaqiKey?: any
  key: string
  value: number
  title: string // pm<sub>2.5</sub>
  name: string  // PM2.5
  scale: number[]
  unitEn: string
  unit: string
  scaleTitle: string[]
  range: [number, number]
}

export enum IndexKey {
  aqi = 'aqi',
  pm2_5 = 'pm2_5',
  pm10 = 'pm10',
  so2 = 'so2',
  no2 = 'no2',
  co = 'co',
  o3 = 'o3',
  temp = 'temp',
  humidity = 'humidity',
  pressure = 'pressure',
  wind_speed = 'wind_speed',
  wind_direction = 'wind_direction',
  tvoc = 'tvoc'

}

export const COLORS = ['#00E400', '#FFFF00', '#FF7E00', '#FF0000', '#99004C', '#7E0023'] // 图例颜色，采用16进制，后面数据有处理
// const COLORS = ['#01E05F', '#FFDE33', '#FF9933', '#7E0023', '#660099', '#7E0023']
export const COLORS_WHITE_STYLE = ['#01E05F', '#FFDE33', '#FF9933', '#7E0023', '#660099', '#7E0023']

export const TVOC_COLORS = ['#5BCC7B', '#D3E671', '#FFF486', '#FFCB6D', '#FF7C66', '#AD82CC']  // 电子围栏tvoc的颜色

export const POLLUTION_INDEXS: IndexTemplate[] = [
  {
    key: 'aqi',
    value: 0,
    title: 'AQI',
    name: 'AQI',
    scale: [0, 50, 100, 150, 200, 300, 500],
    unitEn: '',
    unit: '',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [101, 500],
  },
  {
    key: 'pm2_5',
    value: 1,
    title: 'PM<sub>2.5</sub>',
    name: 'PM2.5',
    scale: [0, 35, 75, 115, 150, 250, 500],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [76, 500],
  },
  {
    key: 'pm10',
    value: 2,
    title: 'PM<sub>10</sub>',
    name: 'PM10',
    scale: [0, 50, 150, 250, 350, 420, 600],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [151, 600],
  },
  {
    key: 'so2',
    value: 3,
    title: 'SO<sub>2</sub>',
    name: 'SO2',
    scale: [0, 150, 500, 650, 800, 1600, 2620],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [501, 2620],
  },
  {
    key: 'no2',
    value: 4,
    title: 'NO<sub>2</sub>',
    name: 'NO2',
    scale: [0, 100, 200, 700, 1200, 2340, 3840],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [201, 3840],
  },
  {
    key: 'co',
    value: 6,
    title: 'CO',
    name: 'CO',
    scale: [0, 5, 10, 35, 60, 90, 150],
    unitEn: 'mg/m³',
    unit: '毫克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [11, 150],
  },
  {
    key: 'o3',
    value: 5,
    title: 'O<sub>3</sub>',
    name: 'O3',
    scale: [0, 160, 200, 300, 400, 800, 1200],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [161, 1200],
  },
  {
    key: 'tvoc',
    value: 7,
    title: 'TVOC',
    name: 'TVOC',
    scale: [0, 0.17, 0.3, 0.5, 0.83, 1.7, 3.3],
    unitEn: 'PPm',
    unit: 'PPm',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [101, 500],
  },
]

const POLLUTION_INDEX_MAP: { [key: string]: IndexTemplate } = POLLUTION_INDEXS.reduce((map, index) => {
  map[index.key] = index
  return map
}, {})

export function findPollutionIndex(key: string): IndexTemplate {
  return POLLUTION_INDEX_MAP[key] || null
}
