

import { Expose, Type } from 'class-transformer'
import { Meta } from '../../types/model'
import { GridFeature } from '../model/base/grid-feature'


export class GridFeaturesDto {
  @Expose()
  @Type(() => GridFeature)
  items: GridFeature[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
