import { Meta } from '@app/core/services/types'

import { Expose, Transform, Type } from 'class-transformer'
import { GridDustBase } from './../model/base/grid-dust-base'


export class GridDustDto {
  code: number

  success: boolean

  @Expose()
  @Type(() => GridDustBase)
  items: GridDustBase[]

  @Expose()
  @Type(() => Meta)
  meta: Meta
}
