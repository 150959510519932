/**
 * label: 文字描述
 * key: key (传给后台的值)
 */
// 默认公用固定值

export {
  MAX_PER_PAGE,
  // COLORS,
  TVOC_INDEXES,
  INDEXES,
  SITES,
  CONTAMINATED_INFO,
  CAR_TYPES,
  COLOR_LIST,
  GRID_SIZE,
  WEATHER_STATIONS,
  FIXED_STATIONS,
  MOBILE_STATIONS,
  MONITORING_EQUIPMENT_STATU,
  STATION_LABELS,
  GRID_TAGS,
  AREAS_LABELS,
  POLLUTION_CLASS,
  SORT_TYPE,
  SORT_TYPE_UN,
  GRIDS_TYPE,
  HOSTPOT_TYPE,
  PUSH_EMIT_CONDITION,
  PUSH_AUDIT_MODE,
  EVENT_TAG,
  EVENT_TARGET_TYPE,
  PUSH_TYPE,
  PRODUCTION_STATUS,
  IS_HAVE_PROCEDURES,
  EVENT_RULE_RADIO_LIST,
  EVENT_LEVEL_COLOR,
  RATIO,
  ROAD_STATUS,
  UPDATE_ERROR,
  DATA_ERROR,
  EVENT_TYPE,
  AUTHORITY_GROUPS,
  CHANGE_TYPE_MODEL,
}

interface IndexTemplate {
  key: string
  value: number
  title: string // pm<sub>2.5</sub>
  name: string  // PM2.5
  scale: number[]
  unitEn: string
  unit: string
  scaleTitle: string[]
  range: [number, number]
}

// export enum IndexKey {
//   aqi = 'aqi',
//   pm2_5 = 'pm2_5',
//   pm10 = 'pm10',
//   so2 = 'so2',
//   no2 = 'no2',
//   co = 'co',
//   o3 = 'o3',
// }

const MAX_PER_PAGE = 4900
// const COLORS = ['#00E400', '#FFFF00', '#FF7E00', '#FF0000', '#99004C', '#7E0023'] // 图例颜色，采用16进制，后面数据有处理
// const COLORS = ['#01E05F', '#FFDE33', '#FF9933', '#7E0023', '#660099', '#7E0023']
// export const COLORS_WHITE_STYLE = ['#01E05F', '#FFDE33', '#FF9933', '#7E0023', '#660099', '#7E0023']
const INDEXES: IndexTemplate[] = [
  {
    key: 'aqi',
    value: 0,
    title: 'AQI',
    name: 'AQI',
    scale: [0, 50, 100, 150, 200, 300, 500],
    unitEn: '',
    unit: '',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [101, 500],
  },
  {
    key: 'pm2_5',
    value: 1,
    title: 'PM<sub>2.5</sub>',
    name: 'PM2.5',
    scale: [0, 35, 75, 115, 150, 250, 500],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [76, 500],
  },
  {
    key: 'pm10',
    value: 2,
    title: 'PM<sub>10</sub>',
    name: 'PM10',
    scale: [0, 50, 150, 250, 350, 420, 600],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [151, 600],
  },
  {
    key: 'so2',
    value: 3,
    title: 'SO<sub>2</sub>',
    name: 'SO2',
    scale: [0, 150, 500, 650, 800, 1600, 2620],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [501, 2620],
  },
  {
    key: 'no2',
    value: 4,
    title: 'NO<sub>2</sub>',
    name: 'NO2',
    scale: [0, 100, 200, 700, 1200, 2340, 3840],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [201, 3840],
  },
  {
    key: 'co',
    value: 6,
    title: 'CO',
    name: 'CO',
    scale: [0, 5, 10, 35, 60, 90, 150],
    unitEn: 'mg/m³',
    unit: '毫克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [11, 150],
  },
  {
    key: 'o3',
    value: 5,
    title: 'O<sub>3</sub>',
    name: 'O3',
    scale: [0, 160, 200, 300, 400, 800, 1200],
    unitEn: 'μg/m³',
    unit: '微克/米³',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [161, 1200],
  },
  {
    key: 'tvoc',
    value: 7,
    title: 'TVOC',
    name: 'TVOC',
    scale: [0, 0.17, 0.3, 0.5, 0.83, 1.7, 3.3],
    unitEn: 'PPm',
    unit: 'PPM',
    scaleTitle: ['优', '良', '轻度', '中度', '重度', '严重'],
    range: [161, 1200],
  },
]


const TVOC_INDEXES = [
  {
    key: 'tvoc',
    value: 0,
    title: 'TVOC',
    name:'TVOC',
    unitEn: 'PPM',

  },
  {
    key: 'wind_speed',
    value: 0,
    title: '风速',
    name:'风速',
    unitEn: 'km/h³',

  },
  {
    key: 'pressure',
    value: 0,
    title: '气压',
    name:'气压',
    unitEn: 'kPa',
  },
  {
    key: 'temp',
    value: 0,
    title: '温度',
    name:'温度',
    unitEn: '°C',
  },
  {
    key: 'wind_direction',
    value: 0,
    title: '风向',
    name:'风向',
    unitEn: '°',
  },
  {
    key: 'humidity',
    value: 0,
    title: '湿度',
    name:'湿度',
    unitEn: '%',
  },
]



const SITES = [
  {
    label: '国控站点',
    title: '国控站点（■）',
    key: 'NCS',
  },
  {
    label: '省控站点',
    title: '省控站点（<span style="width: 8px; height: 8px;background:#fff;display:inline-block;border-radius: 8px;"></span>）',
    key: 'CCS',
  },
  {
    label: '市控站点',
    title: '市控站点（<span style="width: 8px; height: 8px;background:#fff;display:inline-block;border-radius: 8px;"></span>）',
    key: 'sk',
  },
  {
    label: '区控站点',
    title: '区控站点<span style="width: 8px; height: 8px;background:#fff;display:inline-block;border-radius: 8px;"></span>）',
    key: 'qk',
  },
  {
    label: '固定微站',
    title: '固定微站（▲）',
    key: 'MAPS',
  },
  {
    label: '固定子站点',
    title: '固定子站点（▲）',
    key: 'fixZz',
  },
]
const CONTAMINATED_INFO = { // 折线图-污染物信息
  color: ['#61ef83', '#ffdf3f', '#9b69d8', '#51c3dd', '#f45b5b', '#506dff', '#2b908f'],
  title: ['AQI', 'PM2.5', 'PM10', 'SO2', 'NO2', 'CO', 'O3'],
  titleIaqi: ['AQI', 'iAQI-PM2.5', 'iAQI-PM10', 'iAQI-SO2', 'iAQI-NO2', 'iAQI-CO', 'iAQI-O3'],
  keys: ['aqi', 'pm2_5', 'pm10', 'so2', 'no2', 'co', 'o3'],
  keysIaqi: ['aqi', 'pm2_5_iaqi', 'pm10_iaqi', 'so2_iaqi', 'no2_iaqi', 'co_iaqi', 'o3_iaqi'],
}

const CAR_TYPES = [
  {
    key: 'taxi',
    label: '出租车',
  },
  {
    key: 'bus',
    label: '公交车',
  },
  {
    key: 'nc',
    label: '私家车',
  },
]

// 网格特征 地图颜色管理(后台)
const COLOR_LIST = {
  traffic_dynamic: {
    traffic_status: {
      min: 1,
      min_color: '#000000',
      max: 5,
      max_color: '#f98c18',
    },
    smooth_road: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#7f860b',
    },
    slow_road: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#9f3810',
    },
    congest_road: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#901313',
    },
    unknown_road: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#5314b2',
    },
  },
  weather: {
    // 温度
    temp: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#e75616',
      arr: [],
    },
    // 湿度
    humidity: {
      min: 1,
      min_color: '#000000',
      max: 5,
      max_color: '#7f33e7',
      arr: [],
    },
    // 风速
    wind_speed: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#35b12b',
      arr: [],
    },
    // 风向
    wind_degrees: {
      min: 0,
      min_color: '#000000',
      max: 1,
      colors: ['#2eecdc', '#13c16d', '#96c113', '#d16738', '#2eecdc'],
      max_color: '#2eecdc',
      arr: [],
    },
    // 气压
    pressure: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#e37c44',
      arr: [],
    },
    // 降水量
    rainfall: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#e37c44',
      arr: [],
    },
  },
  static: {
    // 海拔高度
    dem: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#9000c5',
      arr: [],
    },
    // 水泊面积
    water_area: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#0042ff',
      arr: [],
    },
    // 绿地面积
    green_area: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#13a017',
      arr: [],
    },
    // 工厂面积
    factory_area: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#ac6f27',
      arr: [],
    },
    // 路网长度
    road_network_length: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#a27b11',
      arr: [],
    },
    // 红绿灯个数
    traffic_light_num: {
      min: 0,
      min_color: '#000000',
      max: 1,
      max_color: '#c01d1d',
      arr: [],
    },
  },
}

/* ------------------------------------和地图相关 */
const GRID_SIZE = [
  {
    key: 70,
    label: '标准',
  },
  {
    key: 140,
    label: '超精',
  },
]

// 气象站点
const WEATHER_STATIONS = [
  {
    label: '气象站点',
    key: 'weather',
  },
]

// 固定站点
const FIXED_STATIONS = [
  {
    label: '国控站点',
    key: 'NCS',
  },
  {
    label: '省控站点',
    key: 'CCS',
  },
  {
    label: '固定微站',
    key: 'MAPS',
    class_type: 'triangle',
  },
]

// 移动站点
const MOBILE_STATIONS = [
  {
    label: '移动微站',
    key: 'mobile',
  },
]

/* ------------------------------------和固定值相关 */
const MONITORING_EQUIPMENT_STATU = [
  {
    key: 0,
    label: '异常',
  },
  {
    key: 1,
    label: '正常',
  },
]

// 站点标签
const STATION_LABELS = [
  {
    label: '基础观察点',
    key: 'basic_observation',
  },
  {
    label: '工业监控点',
    key: 'industrial',
  },
  {
    label: '散煤监控点',
    key: 'loose_coal',
  },
  {
    label: '道路评价点',
    key: 'road_evaluation',
  },
]

// 网格标签
const GRID_TAGS = [
  {
    label: '基础观察',
    key: 'basic_observation',
  },
  {
    label: '工业监控',
    key: 'industrial',
  },
  {
    label: '散煤监控',
    key: 'loose_coal',
  },
  {
    label: '城市评价',
    key: 'city_evaluation',
  },
  {
    label: '重点观察',
    key: 'key_observation',
  },
]

// 区域标签类型
const AREAS_LABELS = [
  {
    label: '行政区域',
    key: 'district',
  },
  {
    label: '功能区域',
    key: 'function',
  },
  {
    label: '重点监测区域',
    key: 'key_monitoring',
  },
  {
    label: '出行建议区域',
    key: 'trip',
  },
]

// 污染等级
const POLLUTION_CLASS = [
  {
    key: 1,
    label: '优',
    color: '#00E400',
  },
  {
    key: 2,
    label: '良',
    color: '#FFFF00',
  },
  {
    key: 3,
    label: '轻度污染',
    color: '#FF7E00',
  },
  {
    key: 4,
    label: '中度污染',
    color: '#FF0000',
  },
  {
    key: 5,
    label: '重度污染',
    color: '#99004C',
  },
  {
    key: 6,
    label: '严重污染',
    color: '#7E0023',
  },
]

// 排序方式
const SORT_TYPE = [
  {
    key: 'ASC',
    label: '正序',
  },
  {
    key: 'DESC',
    label: '倒序',
  },
]

// 达标天数排序
const SORT_TYPE_UN = [
  {
    key: 'DESC',
    label: '正序',
  },
  {
    key: 'ASC',
    label: '倒序',
  },
]

// 网格类型
const GRIDS_TYPE = [
  {
    key: 0,
    label: '全部网格',
  },
  {
    key: 1,
    label: '区域网格',
  },
  {
    key: 2,
    label: '标签网格',
  },
]
// 热点事件类型
const HOSTPOT_TYPE = [
  {
    key: 'casual_happen_short',
    label: '偶发短时',
    value: 0,
  },
  {
    key: 'casual_happen_long',
    label: '偶发持续',
    value: 1,
  },
  {
    key: 'often_happen_short',
    label: '频发短时',
    value: 2,
  },
  {
    key: 'often_happen_long',
    label: '频发持续',
    value: 3,
  },
]

// 推送触发条件
const PUSH_EMIT_CONDITION = [
  {
    label: '满足任意条件时',
    key: 'ANY',
  },
  {
    label: '满足全部条件时',
    key: 'ALL',
  },
]
// 推送方式
const PUSH_AUDIT_MODE = [
  {
    label: '自动推送',
    key: 'AUTO',
  },
]

// 事件标签
const EVENT_TAG = [
  {
    key: 'aqi',
    label: 'AQI',
    title: 'AQI',
  },
  {
    key: 'pm2_5',
    label: 'PM<sub>2.5</sub>',
    title: 'PM2.5',
  },
  {
    key: 'pm10',
    label: 'PM<sub>10</sub>',
    title: 'PM10',
  },
  {
    key: 'so2',
    label: 'SO<sub>2</sub>',
    title: 'SO2',
  },
  {
    key: 'no2',
    label: 'NO<sub>2</sub>',
    title: 'NO2',
  },
  {
    key: 'co',
    label: 'CO',
    title: 'CO',
  },
  {
    key: 'o3',
    label: 'O<sub>3</sub>',
    title: 'O3',
  },
  {
    key: 'device',
    label: '设备断线',
    title: '设备断线',
  },
  {
    key: 'abnormal',
    label: '异常值',
    title: '异常值',
  },
]

// 事件指向类型
const EVENT_TARGET_TYPE = [
  {
    label: '区域',
    key: 'GRID_AREA',
  },
  {
    label: '网格',
    key: 'GRID',
  },
  {
    label: '站点',
    key: 'STATION',
  },
]

// 推送类型
const PUSH_TYPE = [
  {
    label: '短信推送',
    key: 'MESSAGE',
  },
  {
    label: '邮件推送',
    key: 'EMAIL',
  },
]

// 工厂状态
const PRODUCTION_STATUS = [
  {
    label: '生产状态',
    key: 'production',
  },
  {
    label: '停止生产',
    key: 'stop',
  },
  {
    label: '关闭或搬迁',
    key: 'close',
  },
]
// 环境状态
const IS_HAVE_PROCEDURES = [
  {
    label: '环评',
    key: 'EIA',
  },
  {
    label: '备案',
    key: 'ICP',
  },
  {
    label: '验收',
    key: 'CHECK',
  },
]
// 事件规则 综合分析 列表
const EVENT_RULE_RADIO_LIST = [
  {
    label: '最近一个月25天及以上AQI大于100',
    value: 0,
    key: 'month',
  },
  {
    label: '最近一周120个小时及以上AQI大于100',
    value: 1,
    key: 'week',
  },
]
// 事件规则等级颜色标
const EVENT_LEVEL_COLOR = [
  {
    label: 'Ⅰ级',
    color: '#00e400',
    key: 1,
  },
  {
    label: 'Ⅱ级',
    color: '#ffff00',
    key: 2,
  },
  {
    label: 'Ⅲ级',
    color: '#ff7e00',
    key: 3,
  },
  {
    label: 'Ⅳ级',
    color: '#ff0000',
    key: 4,
  },
  {
    label: 'Ⅴ级',
    color: '#99004c',
    key: 5,
  },
  {
    label: 'Ⅵ级',
    color: '#7e0023',
    key: 6,
  },
]
// 事件规则 热点事件 比例规则
const RATIO = [
  {
    value: 0,
    label: '全部',
    key: 'all',
  },
  {
    value: 1,
    label: '前1%',
    key: 'first',
  },
  {
    value: 2,
    label: '前5%',
    key: 'fifth',
  },
  {
    value: 3,
    label: '前10%',
    key: 'tenth',
  },
  {
    value: 4,
    label: '前25%',
    key: 'twentieth',
  },
  {
    value: 5,
    label: '前50%',
    key: 'fiftieth',
  },
]
// 交通动态 路况状态
const ROAD_STATUS = [
  {
    label: '整体畅通',
    key: 1,
  },
  {
    label: '轻度拥堵',
    key: 2,
  },
  {
    label: '中度拥堵',
    key: 3,
  },
  {
    label: '整体拥堵',
    key: 4,
  },
  {
    label: '严重拥堵',
    key: 5,
  },
]
// 上传异常
const UPDATE_ERROR = [
  {
    label: '设备断线',
    key: 'device',
  },
]
// 数值异常
const DATA_ERROR = [
  {
    label: '异常值',
    key: 'abnormal',
  },
]
// 事件类型
const EVENT_TYPE = [
  {
    label: '实时事件',
    key: 'REAL',
  },
  {
    label: '预测事件',
    key: 'FORECAST',
  },
  {
    label: '综合分析',
    key: 'ANALYSIS',
  },
  {
    label: '热点事件',
    key: 'HOTSPOT',
  },
  {
    label: '上传异常',
    key: 'UPLOAD_EXCEPTION',
  },
  {
    label: '数据异常',
    key: 'DATA_EXCEPTION',
  },
]
// 系统账号权限分组
const AUTHORITY_GROUPS = [
  {
    key: 'ordinary',
    label: '普通账号',
  },
  {
    key: 'admin',
    label: '后台管理员',
  },
  {
    key: 'super-admin',
    label: '超级管理员',
  },
]
// 显示模式
const CHANGE_TYPE_MODEL = [
  {
    key: 'standard',
    label: '标准模式',
  },
  {
    key: 'am',
    label: 'AM模式',
  },
]



export enum AirQualitySourceType {
  monitoring = 'monitoring',
  inference = 'inference',
}
