
import { PageParamsPayload } from '@app/core/services/types'
import { Expose, Type } from 'class-transformer'

export class GetFixedStationHourAirQualityParams extends PageParamsPayload {
  @Expose()
  @Type(() => String)
  publishedAtBegin: string

  @Expose()
  @Type(() => String)
  publishedAtEnd: string

  @Expose()
  @Type(() => Array)
  stationCode: string[]

  @Expose()
  @Type(() => Number)
  gridId: number[]

  @Expose()
  @Type(() => String)
  stationName: string[]

  @Expose()
  @Type(() => String)
  stationLevel: string[]

  @Expose()
  @Type(() => String)
  stationType: string[]

  @Expose()
  @Type(() => String)
  sortAsc: string

  @Expose()
  @Type(() => String)
  sortDesc: string

  constructor(params?: {
    publishedAtBegin?: string,
    publishedAtEnd?: string,
    stationCode?: string[],
    gridId?: number[],
    stationName?: string[],
    stationLevel?: string[],
    stationType?: string[],
    sortAsc?: string,
    sortDesc?: string,
    page?: number,
    pageSize?: number,
  }) {
    super()
    if (params) {
      this.initParams(params)
    }
  }
}
