import { COLORS, IndexTemplate, INDEXES, TVOC_INDEXES } from '@app/constants'
import Color from '../color/color'

export { getValueLevel, getValueColor, gradient, indexesDict }

function gradient(startColor: string, endColor: string, step: number): string {
  if (!startColor || !endColor) {
    throw new Error('gradient参数startColor或endColor不存在')
  }
  if ((!step && step !== 0) || isNaN(step)) {
    throw new Error('gradient参数step不存在')
  }

  if (startColor.length !== 7) {
    throw new Error('gradient参数startColor长度错误')
  }
  if (endColor.length !== 7) {
    throw new Error('gradient参数endColor错误')
  }
  if (step > 1 || step < 0) {
    throw new Error('gradient参数step范围有误')
  }
  // 将hex转换为rgb
  const sColor = new Color(startColor).hexToRgb()

  const eColor = new Color(endColor).hexToRgb()
  // 计算R\G\B每一步的差值
  const rStep = (eColor[0] - sColor[0]) * step
  const gStep = (eColor[1] - sColor[1]) * step
  const bStep = (eColor[2] - sColor[2]) * step

  return new Color([parseInt((rStep + sColor[0]).toString(), 10),
  parseInt((gStep + sColor[1]).toString(), 10),
  parseInt((bStep + sColor[2]).toString(), 10)]).rgbToHex()
}

// 对应颜色等级
function getValueLevel(value: number, levels: number[] = []): number {
  if (levels.length < 2) {
    throw new Error('getValueLevel参数levels长度有误')
  }

  if (isNaN(value) || (!value && value !== 0)) {
    throw new Error('getValueLevel参数value不存在')
  }

  if (value < 0) {
    throw new Error('getValueLevel参数value错误')
  }

  for (const i of levels) {
    if ((!i && i !== 0) || isNaN(i)) {
      throw new Error('getValueLevel参数levels错误')
    }
  }
  if (value >= levels[levels.length - 1]) { return levels.length - 1 } // 大值
  if (value === 0) { return 1 }
  return levels.findIndex((v, index): boolean => {
    if (value <= levels[index] || index === levels.length - 1) {
      return true
    }
    return false
  })
}

// 渐变颜色
function getValueColor(
  value: number,
  originLevels: number[] = [],
  originColors: string[] = [],
  type: 'discontinuity' | 'continuity' | string = 'continuity',
): string {
  // const noDataColor = '#c0c0c0'

  if ((!value && value !== 0) || isNaN(value) || value < 0) {
    throw new Error(`getValueColor参数value错误 ${value}`)
    // return noDataColor
  }

  if ((originLevels.length - 1) !== originColors.length ||
    originLevels.length < 2 || originColors.length < 2) {
    throw new Error('getValueColor参数originLevels或originColors长度有误错误')
  }


  for (const i of originLevels) {
    if ((!i && i !== 0) || isNaN(i)) {
      throw new Error('getValueColor参数originLevels错误')
    }
  }

  for (const i of originColors) {
    if (!i || i.length !== 7) {
      throw new Error('getValueColor参数originColors错误')
    }
  }

  if (type === 'continuity') {
    if (value >= originLevels[originLevels.length - 1]) { return originColors[originColors.length - 1] }
    if (value <= originLevels[0]) { return originColors[0] }

    const scale = []

    for (let i = 0; i < originLevels.length; i++) {
      if (i === 0) {
        scale.push(originLevels[i])
      } else {
        scale.push((originLevels[i] - originLevels[i - 1]) / 2 + originLevels[i - 1])
      }

      if (i === originLevels.length - 1) {
        scale.push(originLevels[originLevels.length - 1])
      }
    }

    const colors = [originColors[0], ...originColors, originColors[originColors.length - 1]]

    const rank = getValueLevel(value, scale)

    const color = gradient(colors[rank - 1], colors[rank], (value - scale[rank - 1]) / (scale[rank] - scale[rank - 1]))

    return color
  } else if (type === 'discontinuity') {
    const lev = getValueLevel(value, originLevels)
    if (lev > 0) {
      return originColors[lev - 1]
    }
  } else {
    throw new Error('getValueColor不存在该类型')
  }
}

function indexesDict() {
  return INDEXES.reduce((map, data) => {
    const m = map
    m[data.key] = data
    return map
  }, {})
}

const indexTemplateCache: any = {}

export function findIndexTemplate(key: string) {
  if (indexTemplateCache[key]) {
    return indexTemplateCache[key]
  } else {
    // if(key==='tvoc') {
    //   debugger
    //   const template = TVOC_INDEXES.find(index => index.key === key)
    //   indexTemplateCache[key]  = template
    //   return template
    // }
    // else {
    //   const template = INDEXES.find(index => index.key === key)
    //   indexTemplateCache[key] = template
    //   return template
    // }
    const template = INDEXES.find(index => index.key === key)
    indexTemplateCache[key] = template
    return template

  }
}

export function getIndexValueLevel(key: string, value: number) {
  const template = findIndexTemplate(key)
  return getValueLevel(value, template.scale)
}

export function getIndevValueTitle(key: string, value: number) {
  const template = findIndexTemplate(key)
  const level = getValueLevel(value, template.scale)
  return template.scaleTitle[level - 1]
}

export function getIndexValueColor(key: string, value: number) {
  const template = findIndexTemplate(key)
  return getValueColor(value, template.scale, COLORS)
}

export function getIndexValueScale(key: string, value: number) {
  if (key === 'aqi') {
    return value / 500
  } else if (key === 'tvoc' && value) {
    return value / 500
  }
  const template = findIndexTemplate(key)
  const scale = (value - template.range[0]) / (template.range[1] - template.range[0])
  return scale
}
